import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_PACKAGING, UPDATE_PACKAGING, UPDATE_PACKAGING_SAGA, DELETE_PACKAGING_SAGA } from './actionsTypes';

import api from '@iso/services/repository/packaging'

export function* getPackagings() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_PACKAGING,
      packagings: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updatePackagingSaga({ packagings, packaging }) {
  try {
    if(typeof packaging !== 'undefined' ){
      var isNew = false;
      const aux = (typeof packaging._id !== 'undefined') ?
        yield call(api.updatePackagingData, packaging._id, packaging) :
        yield call(api.createPackagingData, packaging);
      const result = yield call(api.getAll);
      
      if(typeof packaging._id === 'undefined' ){
        isNew = true;
        packagings[0] = aux.data
      }

      yield put({
        type: UPDATE_PACKAGING,
        packagings: result.data,
        packaging: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deletePackagingSaga({ packagings, packaging }) {
  try {
    if(typeof packaging !== 'undefined' ){
      yield call(api.softDeletePackaging, packaging);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_PACKAGING,
        packagings: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_PACKAGING, getPackagings),
    yield takeEvery(UPDATE_PACKAGING_SAGA, updatePackagingSaga),
    yield takeEvery(DELETE_PACKAGING_SAGA, deletePackagingSaga),
  ]);
}
