export const PUBLIC_ROUTE = {
	LANDING: '/',
	SIGN_IN: '/login',
	SIGN_UP: '/signup',
	FORGET_PASSWORD: '/recuperar-acesso',
	RESET_PASSWORD: '/alterar-senha/:hashRecuperacao',
	PAGE_404: '/404',
	PAGE_500: '/500',
	AUTH0_CALLBACK: '/auth0loginCallback',
};

export const PRIVATE_ROUTE = {
	DASHBOARD: '/',
	SCRUM_BOARD: '/scrum-board',
	CALENDAR: '/calendar',
	PDF: '/pdf',
	BOARDS: '/boards',
	PEOPLES: '/people',
	PROFILE: '/profile'
};
