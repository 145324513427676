import http from "../api";

class ContractDataService {
    getAll() {
        return http({
            'url':'/contract',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getContractById(id) {
        return http({
            'url':'/contract/'+id,
            'method':'GET'
        });
    }

    createContractData(data) {
        return http({
            'url':'/contract',
            'method':'POST',
            'data': data
        });
    }

    updateContractData(id, data) {
        return http({
            'url':'/contract/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteContract(id) {
        return http({
            'url':'/contract/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeContract(id) {
        return http({
            'url':'/contract/'+id,
            'method':'DELETE'
        });
    }
}

export default new ContractDataService();