import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_GOAL, UPDATE_GOAL, UPDATE_GOAL_SAGA, DELETE_GOAL_SAGA } from './actionsTypes';

import api from '@iso/services/repository/goal'

export function* getGoals() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_GOAL,
      goals: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateGoalSaga({ goals, goal }) {
  try {
    if(typeof goal !== 'undefined' ){
      var isNew = false;
      const aux = (typeof goal._id !== 'undefined') ?
        yield call(api.updateGoalData, goal._id, goal) :
        yield call(api.createGoalData, goal);
      
      if(typeof goal._id === 'undefined' ){
        isNew = true;
        goals[0] = aux.data
      }

      yield put({
        type: UPDATE_GOAL,
        goals,
        goal: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteGoalSaga({ goals, goal }) {
  try {
    if(typeof goal !== 'undefined' ){
      yield call(api.softDeleteGoalData, goal);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_GOAL,
        goals: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_GOAL, getGoals),
    yield takeEvery(UPDATE_GOAL_SAGA, updateGoalSaga),
    yield takeEvery(DELETE_GOAL_SAGA, deleteGoalSaga),
  ]);
}
