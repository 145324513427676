
export const newBillReceive = {
    fornecedor: "",
    history: "",
    emission: "",
    maturity: "",
    value: "",
    netValue: "",
    balance: "",
    received: "",
    category: "",
    installments: "",
    occurrence: "",
    dayMaturity: "",
    situation: 'Ativo'
};