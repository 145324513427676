import clone from 'clone';
import {
    UPDATE_RECURRENCE
} from './actionsTypes'

const initState = {
  recurrences: [],
  initialRecurrence: false,
  currentRecurrence: {},
  editableRecurrence: {},
  isNewRecurrence: false,
  enableEditView: false,
  recurrence: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_RECURRENCE: {
      const currentRecurrence = action.recurrence
        ? action.recurrence
        : state.currentRecurrence;

      return {
        ...state,
        recurrences: action.recurrences,
        currentRecurrence: clone(currentRecurrence),
        initialRecurrence: true,
        isNewRecurrence: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
