import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_CONTRACT, UPDATE_CONTRACT, UPDATE_CONTRACT_SAGA, DELETE_CONTRACT_SAGA } from './actionsTypes';

import api from '@iso/services/repository/contract'

export function* getContracts() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_CONTRACT,
      contracts: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateContractSaga({ contracts, contract }) {
  try {
    if(typeof contract !== 'undefined' ){
      var isNew = false;
      const aux = (typeof contract._id !== 'undefined') ?
        yield call(api.updateContractData, contract._id, contract) :
        yield call(api.createContractData, contract);
      const result = yield call(api.getAll);

      if(typeof contract._id === 'undefined' ){
        isNew = true;
        contracts[0] = aux.data
      }

      yield put({
        type: UPDATE_CONTRACT,
        contracts: result.data,
        contract: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteContractSaga({ contracts, contract }) {
  try {
    if(typeof contract !== 'undefined' ){
      yield call(api.softDeleteContract, contract);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_CONTRACT,
        contracts: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_CONTRACT, getContracts),
    yield takeEvery(UPDATE_CONTRACT_SAGA, updateContractSaga),
    yield takeEvery(DELETE_CONTRACT_SAGA, deleteContractSaga),
  ]);
}
