import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_TYPE_FREIGHT, UPDATE_TYPE_FREIGHT, UPDATE_TYPE_FREIGHT_SAGA, DELETE_TYPE_FREIGHT_SAGA } from './actionsTypes';

import api from '@iso/services/repository/comercialCondition/typeFreight';

export function* getTypesFreight() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_TYPE_FREIGHT,
      typesFreight: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateTypeFreightSaga({ typesFreight, typeFreight }) {
  try {
    if(typeof typeFreight !== 'undefined' ){
      var isNew = false;
      const aux = (typeof typeFreight._id !== 'undefined') ?
        yield call(api.updateTypeFreightData, typeFreight._id, typeFreight) :
        yield call(api.createTypeFreightData, typeFreight);
      const result = yield call(api.getAll);
      
      if(typeof typeFreight._id === 'undefined' ){
        isNew = true;
        typesFreight[0] = aux.data
      }

      yield put({
        type: UPDATE_TYPE_FREIGHT,
        typesFreight: result.data,
        typeFreight: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}


export function* deleteTypeFreightSaga({ typesFreight, typeFreight }) {
  try {
    if(typeof typeFreight !== 'undefined' ){
      yield call(api.softDeleteTypeFreight, typeFreight);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_TYPE_FREIGHT,
        typesFreight: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_TYPE_FREIGHT, getTypesFreight),
    yield takeEvery(UPDATE_TYPE_FREIGHT_SAGA, updateTypeFreightSaga),
    yield takeEvery(DELETE_TYPE_FREIGHT_SAGA, deleteTypeFreightSaga)
  ]);
}
