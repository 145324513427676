import { getBaseUrl } from "../../library/helpers/utility";
import http from "../api";
import axios from "axios";

class ProductDataService {
    getAll() {
        return http({
            'url':'/productsAndServices',
            'method':'GET',
        });
    }

    getAllWithFiles() {
        return http({
            'url':'/productsAndServices/findAllWithFiles',
            'method':'GET'
        });
    }

    getProductById(id) {
        return http({
            'url':'/productsAndServices/'+id,
            'method':'GET'
        });
    }

    createProductData(data) {
        return http({
            'url':'/productsAndServices',
            'method':'POST',
            headers: {
                'Content-Type':'multipart/form-data; boundary=----WebKitFormBoundaryYfmIyu4VricsS1by',
                'x-tenant-id': localStorage.getItem('tenantId')
            },
            withCredentials:true,
            'data': data
        });
    }

    updateProductData(id, data) {
        return axios({
            baseURL: getBaseUrl(),
            method:'PUT',
            url:'/productsAndServices/'+id,
            headers: {
                'Content-Type':'multipart/form-data; boundary=----WebKitFormBoundaryYfmIyu4VricsS1by',
                'x-tenant-id': localStorage.getItem('tenantId')
            },
            withCredentials:true,
            'data': data
        });
    }

    softDeleteProduct(id) {
        return http({
            'url':'/productsAndServices/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeProduct(id) {
        return http({
            'url':'/productsAndServices/'+id,
            'method':'DELETE'
        });
    }
}

export default new ProductDataService();