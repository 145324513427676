import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_STAGE, UPDATE_STAGE, UPDATE_STAGE_SAGA, DELETE_STAGE_SAGA } from './actionsTypes';

import api from '@iso/services/repository/stage'

export function* getStages() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_STAGE,
      stages: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateStageSaga({ stages, stage }) {
  try {
    if(typeof stage !== 'undefined' ){
      var isNew = false;
      const aux = (typeof stage._id !== 'undefined') ?
        yield call(api.updateStageData, stage._id, stage) :
        yield call(api.createStageData, stage);
      
      if(typeof stage._id === 'undefined' ){
        isNew = true;
        stages[0] = aux.data
      }

      yield put({
        type: UPDATE_STAGE,
        stages,
        stage: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteStageSaga({ stages, stage }) {
  try {
    if(typeof stage !== 'undefined' ){
      yield call(api.removeStage, stage);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_STAGE,
        stages: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_STAGE, getStages),
    yield takeEvery(UPDATE_STAGE_SAGA, updateStageSaga),
    yield takeEvery(DELETE_STAGE_SAGA, deleteStageSaga),
  ]);
}
