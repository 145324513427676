import { all, takeEvery, call, put } from 'redux-saga/effects';
import { GET_ATTENDANT, UPDATE_ATTENDANT } from './actionsTypes';

import api from '@iso/services/repository/people'

export function* getAttendants() {
  try {
    const result = yield call(api.getAllAttendants);
    yield put({
      type: UPDATE_ATTENDANT,
      attendants: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_ATTENDANT, getAttendants)
  ]);
}
