import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_SEGMENT, UPDATE_SEGMENT, UPDATE_SEGMENT_SAGA, DELETE_SEGMENT_SAGA } from './actionsTypes';

import api from '@iso/services/repository/segment'

export function* getSegments() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_SEGMENT,
      segments: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateSegmentSaga({ segments, segment }) {
  try {
    if(typeof segment !== 'undefined' ){
      var isNew = false;
      const aux = (typeof segment._id !== 'undefined') ?
        yield call(api.updateSegmentData, segment._id, segment) :
        yield call(api.createSegmentData, segment);
      const result = yield call(api.getAll);
      
      if(typeof segment._id === 'undefined' ){
        isNew = true;
        segments[0] = aux.data
      }

      yield put({
        type: UPDATE_SEGMENT,
        segments: result.data,
        segment: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteSegmentSaga({ segments, segment }) {
  try {
    if(typeof segment !== 'undefined' ){
      yield call(api.softDeleteSegment, segment);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_SEGMENT,
        segments: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_SEGMENT, getSegments),
    yield takeEvery(UPDATE_SEGMENT_SAGA, updateSegmentSaga),
    yield takeEvery(DELETE_SEGMENT_SAGA, deleteSegmentSaga),
  ]);
}
