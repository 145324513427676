import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_USER, UPDATE_USER, UPDATE_USER_SAGA, DELETE_USER_SAGA } from './actionsTypes';

import api from '@iso/services/repository/user'

export function* getUsers() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_USER,
      users: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateUserSaga({ users, user }) {
  try {
    if(typeof user !== 'undefined' ){
      var isNew = false;
      const aux = (typeof user._id !== 'undefined') ?
        yield call(api.updateUserData, user._id, user) :
        yield call(api.createUserData, user);
      const result = yield call(api.getAll);

      if(typeof user._id === 'undefined' ){
        isNew = true;
        users[0] = aux.data
      }

      yield put({
        type: UPDATE_USER,
        users: result.data,
        user: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteUserSaga({ users, user }) {
  try {
    if(typeof user !== 'undefined' ){
      yield call(api.softDeleteUser, user);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_USER,
        users: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_USER, getUsers),
    yield takeEvery(UPDATE_USER_SAGA, updateUserSaga),
    yield takeEvery(DELETE_USER_SAGA, deleteUserSaga),
  ]);
}
