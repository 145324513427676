import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_TEMPLATE, UPDATE_TEMPLATE, UPDATE_TEMPLATE_SAGA, DELETE_TEMPLATE_SAGA } from './actionsTypes';

import api from '@iso/services/repository/templates'

export function* getTemplates() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_TEMPLATE,
      templates: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateTemplateSaga({ templates, template }) {
  try {
    if(typeof template !== 'undefined' ){
      var isNew = false;
      const aux = (typeof template._id !== 'undefined') ?
        yield call(api.updateTemplateData, template._id, template) :
        yield call(api.createTemplateData, template);
      const result = yield call(api.getAll);

      if(typeof template._id === 'undefined' ){
        isNew = true;
        templates[0] = aux.data
      }

      yield put({
        type: UPDATE_TEMPLATE,
        templates: result.data,
        template: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteTemplateSaga({ templates, template }) {
  try {
    if(typeof template !== 'undefined' ){
      yield call(api.softDeleteTemplate, template);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_TEMPLATE,
        templates: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_TEMPLATE, getTemplates),
    yield takeEvery(UPDATE_TEMPLATE_SAGA, updateTemplateSaga),
    yield takeEvery(DELETE_TEMPLATE_SAGA, deleteTemplateSaga),
  ]);
}
