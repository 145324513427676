import clone from 'clone';
import { UPDATE_PRODUCT } from './actionsTypes';

const initState = {
  products: [],
  initialProducts: false,
  currentProduct: {},
  editableProduct: {},
  isNewProduct: false,
  enableEditView: false,
  product: {
    key: null,
    id: new Date().getTime(),
    name: '',
    codeSKU: '',
    un_medida: '',
    type: '',
    preco_venda: '',
    preco_promocao: '',
    desc_custa: '',
    categoria: '',
    marca: '',
    embalagem: '',
    peso_liquido: '',
    peso_bruto: '',
    largura: '',
    altura: '',
    profundidade: '',
    desc_completa: '',
    estoque_min: 0,
    anexos: [],
    fornecedor: [],
    situation: 'ativo', // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  }
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_PRODUCT: {
      const currentProduct = action.product
        ? action.product
        : state.currentProduct;
      return {
        ...state,
        products: action.products,
        currentProduct: clone(currentProduct),
        initialProducts: true,
        isNewProduct: false,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
