import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Goal from '@iso/redux/goal/reducer';
import Bills from '@iso/redux/bill/reducer';
import Tasks from '@iso/redux/task/reducer';
import Users from '@iso/redux/users/reducer';
import Brand from '@iso/redux/brand/reducer';
import Plans from '@iso/redux/plans/reducer';
import modal from '@iso/redux/modal/reducer';
// import Boards from '@iso/redux/boards/reducer';
import Stages from '@iso/redux/stage/reducer';
import People from '@iso/redux/people/reducer';
import Lesson from '@iso/redux/lesson/reducer';
import Segments from '@iso/redux/segment/reducer';
import BillsPay from '@iso/redux/billsPay/reducer';
import Calendar from '@iso/redux/calendar/reducer';
import Products from '@iso/redux/products/reducer';
import Campaign from '@iso/redux/campaign/reducer';
import Deadlines from '@iso/redux/deadline/reducer';
import Contracts from '@iso/redux/contract/reducer';
import Templates from '@iso/redux/templates/reducer';
import Packaging from '@iso/redux/packaging/reducer';
import Attendants from '@iso/redux/attendants/reducer';
import ScrumBoard from '@iso/redux/scrumBoard/reducer';
import Recurrences from '@iso/redux/recurrence/reducer';
import CostsCenter from '@iso/redux/costCenter/reducer';
import PeopleGroup from '@iso/redux/peopleGroup/reducer';
import PaymentForms from '@iso/redux/paymentForm/reducer';
import TypesFreight from '@iso/redux/typeFreight/reducer';
import BankAccounts from '@iso/redux/bankAccount/reducer';
import AdictionCost from '@iso/redux/adictionCost/reducer';
import TemplateForm from '@iso/redux/templateForm/reducer';
import BillsReceive from '@iso/redux/billsReceive/reducer';
import CardTemplates from '@iso/redux/cardTemplate/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import TypesGuarantee from '@iso/redux/typeGuarantee/reducer';
import OrdersCategory from '@iso/redux/orderCategory/reducer';
import ProductGrid from '@iso/redux/productGrid/reducer';
import ProductsOrigin from '@iso/redux/productOrigin/reducer';
import AdictionBudget from '@iso/redux/adictionBudget/reducer';
import FinancesCategory from '@iso/redux/financeCategory/reducer';
import UnitsMeasurement from '@iso/redux/unitMeasurement/reducer';
import AdictionConstant from '@iso/redux/adictionConstant/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ProductsCategory from '@iso/redux/productsCategory/reducer';
import MessagingCampaigns from '@iso/redux/messagingCampaign/reducer';
import PaymentConditions from '@iso/redux/paymentCondition/reducer';

// import Mails from '@iso/redux/mail/reducer';
// import Box from '@iso/redux/box/reducer';
// import Notes from '@iso/redux/notes/reducer';
// import Todos from '@iso/redux/todos/reducer';
// import Contacts from '@iso/redux/contacts/reducer';
// import Cards from '@iso/redux/card/reducer';
// import Chat from '@iso/redux/chat/reducers';
// import DynamicChartComponent from '@iso/redux/dynamicEchart/reducer';
// import Ecommerce from '@iso/redux/ecommerce/reducer';
// import Invoices from '@iso/redux/invoice/reducer';
// import YoutubeSearch from '@iso/redux/youtubeSearch/reducers';
// import Articles from '@iso/redux/articles/reducers';
// import Investors from '@iso/redux/investors/reducers';
// import drawer from '@iso/redux/drawer/reducer';
// import profile from '@iso/redux/profile/reducer';
// import githubSearch from '@iso/redux/githubSearch/reducers';
// import quiz from '@iso/redux/quiz/reducer';

export default combineReducers({
  App,
  Auth,
  // Boards,
  Goal,
  Brand,
  modal,
  Bills,
  Users,
  Plans,
  Tasks,
  People,
  Lesson,
  Stages,
  Calendar,
  Campaign,
  Segments,
  Products,
  BillsPay,
  Contracts,
  Deadlines,
  Packaging,
  Templates,
  ScrumBoard,
  Attendants,
  CostsCenter,
  Recurrences,
  PeopleGroup,
  AdictionCost,
  BankAccounts,
  ProductGrid,
  PaymentForms,
  TypesFreight,
  BillsReceive,
  TemplateForm,
  CardTemplates,
  ThemeSwitcher,
  AdictionBudget,
  TypesGuarantee,
  OrdersCategory,
  ProductsOrigin,
  AdictionConstant,
  FinancesCategory,
  UnitsMeasurement,
  ProductsCategory,
  LanguageSwitcher,
  PaymentConditions,
  MessagingCampaigns,
  // Mails,
  // Box,
  // Notes,
  // Todos,
  // Contacts,
  // Cards,
  // Chat,
  // DynamicChartComponent,
  // Ecommerce,
  // Invoices,
  // YoutubeSearch,
  // Articles,
  // Investors,
  // drawer,
  // profile,
  // githubSearch,
  // quiz,
});
