import http from "../api";

class ProductGridDataService {
    getAll() {
        return http({
            'url':'/productGrid',
            'method':'GET'
        });
    }

    getAllSimple() {
        return http({
            'url':'/productGrid/simple',
            'method':'GET'
        });
    }

    getProductGridById(id) {
        return http({
            'url':'/productGrid/'+id,
            'method':'GET'
        });
    }

    createProductGridData(data) {
        return http({
            'url':'/productGrid',
            'method':'POST',
            'data': data
        });
    }

    updateProductGridData(id, data) {
        return http({
            'url':'/productGrid/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteProductGrid(id) {
        return http({
            'url':'/productGrid/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeProductGrid(id) {
        return http({
            'url':'/productGrid/'+id,
            'method':'DELETE'
        });
    }
}

export default new ProductGridDataService();