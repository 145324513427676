import { all, takeEvery, put } from 'redux-saga/effects';
import { GET_PLAN, UPDATE_PLAN } from './actionsTypes';

// import api from '@iso/services/repository/peopleGroup'

export function* getPlan() {
  try {
    // const result = yield call(api.getAll);
    const result = [
      {
        _id: 'b9c11952-f77d-4d2b-814e-0761db1458d5',
        name: 'Prata',
        qtdUsuarios: '1',
        valor: '25',
        situation: 'Ativo',
        createdAt: '2022-12-19T17:18:17.751Z',
        updatedAt: '2023-02-13T16:05:42.786Z',
      },
      {
        _id: 'b9c11952-f78d-4d2b-814e-0761db1459d6',
        name: 'Ouro',
        qtdUsuarios: '2',
        valor: '50',
        situation: 'Ativo',
        createdAt: '2022-12-19T17:18:17.751Z',
        updatedAt: '2023-02-13T16:05:42.786Z',
      }
    ];
    yield put({
      type: UPDATE_PLAN,
      plans: result
    })
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_PLAN, getPlan),
  ]);
}
