import clone from 'clone';
import {
    UPDATE_BILL
} from './actionsTypes'

const initState = {
  bills: [],
  initialBill: false,
  currentBill: {},
  editableBill: {},
  isNewBill: false,
  enableEditView: false,
  bill: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_BILL: {
      const currentBill = action.bill
        ? action.bill
        : state.currentBill;

      return {
        ...state,
        bills: action.bills,
        currentBill: clone(currentBill),
        initialBill: true,
        isNewBill: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
