import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_ADICTION_BUDGET, UPDATE_ADICTION_BUDGET, UPDATE_ADICTION_BUDGET_SAGA, DELETE_ADICTION_BUDGET_SAGA } from './actionsTypes';

import api from '@iso/services/repository/comercialCondition/adictionBudget';

export function* getAdictionBudgets() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_ADICTION_BUDGET,
      adictionBudgets: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateAdictionBudgetSaga({ adictionBudgets, adictionBudget }) {
  try {
    if(typeof adictionBudget !== 'undefined' ){
      var isNew = false;
      const aux = (typeof adictionBudget._id !== 'undefined') ?
        yield call(api.updateAdictionBudgetData, adictionBudget._id, adictionBudget) :
        yield call(api.createAdictionBudgetData, adictionBudget);
      
      if(typeof adictionBudget._id === 'undefined' ){
        isNew = true;
        adictionBudgets[0] = aux.data
      }

      yield put({
        type: UPDATE_ADICTION_BUDGET,
        adictionBudgets,
        adictionBudget: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteAdictionBudgetSaga({ adictionBudgets, adictionBudget }) {
  try {
    if(typeof adictionBudget !== 'undefined' ){
      yield call(api.softDeleteAdictionBudget, adictionBudget);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_ADICTION_BUDGET,
        adictionBudgets: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_ADICTION_BUDGET, getAdictionBudgets),
    yield takeEvery(UPDATE_ADICTION_BUDGET_SAGA, updateAdictionBudgetSaga),
    yield takeEvery(DELETE_ADICTION_BUDGET_SAGA, deleteAdictionBudgetSaga),
  ]);
}
