import clone from 'clone';
import {
    UPDATE_PRODUCT_ORIGIN
} from './actionsTypes'

const initState = {
  productsOrigin: [],
  initialProductsOrigin: false,
  currentProductOrigin: {},
  editableProductOrigin: {},
  isNewProductOrigin: false,
  enableEditView: false,
  productOrigin: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_PRODUCT_ORIGIN: {
      const currentProductOrigin = action.productOrigin
        ? action.productOrigin
        : state.currentProductOrigin;

      return {
        ...state,
        productsOrigin: action.productsOrigin,
        currentProductOrigin: clone(currentProductOrigin),
        initialProductsOrigin: true,
        isNewProductOrigin: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
