import http from "../api";

class StageDataService {
    getAll() {
        return http({
            'url':'/columns',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getStageById(id) {
        return http({
            'url':'/columns/'+id,
            'method':'GET'
        });
    }

    createStageData(data) {
        return http({
            'url':'/columns',
            'method':'POST',
            'data': data
        });
    }

    updateStageData(id, data) {
        return http({
            'url':'/columns/'+id,
            'method':'PUT',
            'data': data
        });
    }

    removeStage(id) {
        return http({
            'url':'/columns/'+id,
            'method':'DELETE'
        });
    }
}

export default new StageDataService();