import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_VIDEO_LESSION, UPDATE_VIDEO_LESSION, UPDATE_VIDEO_LESSION_SAGA, DELETE_VIDEO_LESSION_SAGA } from './actionsTypes';

import api from '@iso/services/repository/lesson'

export function* getLessons() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_VIDEO_LESSION,
      lessons: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateLessonSaga({ lessons, lesson }) {
  try {
    if(typeof lesson !== 'undefined' ){
      var isNew = false;
      const aux = (typeof lesson._id !== 'undefined') ?
        yield call(api.updateLessonData, lesson._id, lesson) :
        yield call(api.createLessonData, lesson);
      
      if(typeof lesson._id === 'undefined' ){
        isNew = true;
        lessons[0] = aux.data
      }

      yield put({
        type: UPDATE_VIDEO_LESSION,
        lessons,
        lesson: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteLessonSaga({ lessons, lesson }) {
  try {
    if(typeof lesson !== 'undefined' ){
      yield call(api.removeLesson, lesson);
      yield put({
        type: UPDATE_VIDEO_LESSION,
        lessons
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_VIDEO_LESSION, getLessons),
    yield takeEvery(UPDATE_VIDEO_LESSION_SAGA, updateLessonSaga),
    yield takeEvery(DELETE_VIDEO_LESSION_SAGA, deleteLessonSaga),
  ]);
}
