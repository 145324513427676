import clone from 'clone';
import {
    UPDATE_ORDER_CATEGORY
} from './actionsTypes'

const initState = {
  ordersCategory: [],
  initialOrdersCategory: false,
  currentOrderCategory: {},
  editableOrderCategory: {},
  isNewOrderCategory: false,
  enableEditView: false,
  orderCategory: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_ORDER_CATEGORY: {
      const currentOrderCategory = action.orderCategory
        ? action.orderCategory
        : state.currentOrderCategory;

      return {
        ...state,
        ordersCategory: action.ordersCategory,
        currentOrderCategory: clone(currentOrderCategory),
        initialOrdersCategory: true,
        isNewOrderCategory: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
