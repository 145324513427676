import { LOGOUT, LOGIN_SUCCESS } from './actionsType';

const initState = { idToken: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        idToken: action.token,
      };
    case LOGOUT:
      return {
        idToken: false,
      };;
    default:
      return state;
  }
}
