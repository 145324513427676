import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_ADICTION_COST, UPDATE_ADICTION_COST, UPDATE_ADICTION_COST_SAGA, DELETE_ADICTION_COST_SAGA } from './actionsTypes';

import api from '@iso/services/repository/comercialCondition/adictionCost';

export function* getAdictionCosts() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_ADICTION_COST,
      adictionCosts: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateAdictionCostSaga({ adictionCosts, adictionCost }) {
  try {
    if(typeof adictionCost !== 'undefined' ){
      var isNew = false;
      const aux = (typeof adictionCost._id !== 'undefined') ?
        yield call(api.updateAdictionCostData, adictionCost._id, adictionCost) :
        yield call(api.createAdictionCostData, adictionCost);
      
      if(typeof adictionCost._id === 'undefined' ){
        isNew = true;
        adictionCosts[0] = aux.data
      }

      yield put({
        type: UPDATE_ADICTION_COST,
        adictionCosts,
        adictionCost: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteAdictionCostSaga({ adictionCosts, adictionCost }) {
  try {
    if(typeof adictionCost !== 'undefined' ){
      yield call(api.softDeleteAdictionCost, adictionCost);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_ADICTION_COST,
        adictionCosts: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_ADICTION_COST, getAdictionCosts),
    yield takeEvery(UPDATE_ADICTION_COST_SAGA, updateAdictionCostSaga),
    yield takeEvery(DELETE_ADICTION_COST_SAGA, deleteAdictionCostSaga),
  ]);
}
