import clone from 'clone';
import {
    UPDATE_PACKAGING
} from './actionsTypes'

const initState = {
  packagings: [],
  initialPackaging: false,
  currentPackaging: {},
  editablePackaging: {},
  isNewPackaging: false,
  enableEditView: false,
  packaging: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_PACKAGING: {
      const currentPackaging = action.packaging
        ? action.packaging
        : state.currentPackaging;

      return {
        ...state,
        packagings: action.packagings,
        currentPackaging: clone(currentPackaging),
        initialPackaging: true,
        isNewPackaging: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
