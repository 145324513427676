import clone from 'clone';
import {
    UPDATE_BANK_ACCOUNT
} from './actionsTypes'

const initState = {
  bankAccounts: [],
  initialBankAccount: false,
  currentBankAccount: {},
  editableBankAccount: {},
  isNewBankAccount: false,
  enableEditView: false,
  bankAccount: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_BANK_ACCOUNT: {
      const currentBankAccount = action.bankAccount
        ? action.bankAccount
        : state.currentBankAccount;

      return {
        ...state,
        bankAccounts: action.bankAccounts,
        currentBankAccount: clone(currentBankAccount),
        initialBankAccount: true,
        isNewBankAccount: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
