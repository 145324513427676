import { LOGOUT, LOGIN_REQUEST, CHECK_AUTHORIZATION } from './actionsType'
const actions = {
  checkAuthorization: () => ({ type: CHECK_AUTHORIZATION }),
  login: (token = false) => ({
    type: LOGIN_REQUEST,
    payload: { token },
  }),
  logout: () => ({
    type: LOGOUT,
  }),
};
export default actions;
