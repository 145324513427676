import http from "../api";

class PeopleGroupDataService {
    getAll() {
        return http({
            'url':'/peopleGroup',
            'method':'GET',
        });
    }

    getPeopleGroupById(id) {
        return http({
            'url':'/peopleGroup/'+id,
            'method':'GET'
        });
    }

    createPeopleGroup(data) {
        return http({
            'url':'/peopleGroup',
            'method':'POST',
            'data': data
        });
    }

    updatePeopleGroup(id, data) {
        return http({
            'url':'/peopleGroup/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeletePeopleGroup(id) {
        return http({
            'url':'/peopleGroup/softDelete/'+id,
            'method':'PUT'
        });
    }

    removePeopleGroup(id) {
        return http({
            'url':'/peopleGroup/'+id,
            'method':'DELETE'
        });
    }
}

export default new PeopleGroupDataService();