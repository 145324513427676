import clone from 'clone';
import {
    UPDATE_ADICTION_COST
} from './actionsTypes'

const initState = {
  adictionCosts: [],
  initialAdictionCosts: false,
  currentAdictionCost: {},
  editableAdictionCost: {},
  isNewAdictionCost: false,
  enableEditView: false,
  adictionCost: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_ADICTION_COST: {
      const currentAdictionCost = action.adictionCost
        ? action.adictionCost
        : state.currentAdictionCost;

      return {
        ...state,
        adictionCosts: action.adictionCosts,
        currentAdictionCost: clone(currentAdictionCost),
        initialAdictionCosts: true,
        isNewAdictionCost: false,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
