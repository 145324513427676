import clone from 'clone';
import { UPDATE_BOARD } from './actionsTypes';

const initState = {
  boards: [],
  initialBoards: false,
  currentBoard: {},
  editableBoard: {},
  isNewBoard: false,
  enableEditView: false,
  board: {
    key: null,
    id: new Date().getTime(),
    name: '',
    updateTime: 0,
    salesForm: '',
    kpis: [],
    filter: [],
    stages: [],
    cardTemplate: '',
    origin: '',
    buttons: [],
    validation: [],
    situation: 'ativo', // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  }
};
export default function scrumBoardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_BOARD: {
      const currentBoard = action.board
        ? action.board
        : state.currentBoard;
      return {
        ...state,
        boards: action.boards,
        currentBoard: clone(currentBoard),
        initialBoards: false,
        isNewBoard: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
