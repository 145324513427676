import http from "../api";

class RecurrenceDataService {
    getAll() {
        return http({
            'url':'/recurrence',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getRecurrenceById(id) {
        return http({
            'url':'/recurrence/'+id,
            'method':'GET'
        });
    }

    createRecurrenceData(data) {
        return http({
            'url':'/recurrence',
            'method':'POST',
            'data': data
        });
    }

    updateRecurrenceData(id, data) {
        return http({
            'url':'/recurrence/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteRecurrence(id) {
        return http({
            'url':'/recurrence/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeRecurrence(id) {
        return http({
            'url':'/recurrence/'+id,
            'method':'DELETE'
        });
    }
}

export default new RecurrenceDataService();