import http from "../api";

class PackagingDataService {
    getAll() {
        return http({
            'url':'/packaging',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getPackagingById(id) {
        return http({
            'url':'/packaging/'+id,
            'method':'GET'
        });
    }

    createPackagingData(data) {
        return http({
            'url':'/packaging',
            'method':'POST',
            'data': data
        });
    }

    updatePackagingData(id, data) {
        return http({
            'url':'/packaging/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeletePackaging(id) {
        return http({
            'url':'/packaging/softDelete/'+id,
            'method':'PUT'
        });
    }

    removePackaging(id) {
        return http({
            'url':'/packaging/'+id,
            'method':'DELETE'
        });
    }
}

export default new PackagingDataService();