import http from "../api";

class PeopleDataService {
    getAll() {
        return http({
            'url':'/people',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getSmallAll() {
        return http({
            'url':'/people/findSmallAll',
            'method':'GET'
        });
    }

    getAllAttendants() {
        return http({
            'url':'/people/attendants',
            'method':'GET'
        });
    }

    getPesonById(id) {
        return http({
            'url':'/people/'+id,
            'method':'GET'
        });
    }

    getByParameter(what, parameter) {
        return http({
            'url':'/people/find/'+what+'/'+parameter,
            'method':'GET'
        });
    }

    createPerson(data) {
        return http({
            'url':'/people',
            'method':'POST',
            'data': data
        });
    }

    updatePerson(id, data) {
        return http({
            'url':'/people/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeletePerson(id) {
        return http({
            'url':'/people/softDelete/'+id,
            'method':'PUT'
        });
    }

    removePerson(id) {
        return http({
            'url':'/people/'+id,
            'method':'DELETE'
        });
    }
}

export default new PeopleDataService();