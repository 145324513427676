import http from "../api";

class TaskDataService {
    getAll() {
        return http({
            'url':'/task',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getTaskById(id) {
        return http({
            'url':'/task/'+id,
            'method':'GET'
        });
    }

    createTaskData(data) {
        return http({
            'url':'/task',
            'method':'POST',
            'data': data
        });
    }

    updateTaskData(id, data) {
        return http({
            'url':'/task/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteTask(id) {
        return http({
            'url':'/task/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeTask(id) {
        return http({
            'url':'/task/'+id,
            'method':'DELETE'
        });
    }
}

export default new TaskDataService();