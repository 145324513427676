import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_CARD_TEMPLATE, UPDATE_CARD_TEMPLATE, UPDATE_CARD_TEMPLATE_SAGA, DELETE_CARD_TEMPLATE_SAGA } from './actionsTypes';

import api from '@iso/services/repository/cardTemplates'

export function* getCardTemplate() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_CARD_TEMPLATE,
      cardTemplates: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateCardTemplateSaga({ cardTemplates, cardTemplate }) {
  try {
    if(typeof cardTemplate !== 'undefined' ){
      var isNew = false;
      const aux = (typeof cardTemplate._id !== 'undefined') ?
        yield call(api.updateCardTemplateData, cardTemplate._id, cardTemplate) :
        yield call(api.createCardTemplateData, cardTemplate);
      const result = yield call(api.getAll);
      
      if(typeof cardTemplate._id === 'undefined' ){
        isNew = true;
        cardTemplates[0] = aux.data
      }

      yield put({
        type: UPDATE_CARD_TEMPLATE,
        cardTemplates: result.data,
        cardTemplate: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteCardTemplateSaga({ cardTemplates, cardTemplate }) {
  try {
    if(typeof cardTemplate !== 'undefined' ){
      yield call(api.softDeleteCardTemplate, cardTemplate);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_CARD_TEMPLATE,
        cardTemplates: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_CARD_TEMPLATE, getCardTemplate),
    yield takeEvery(UPDATE_CARD_TEMPLATE_SAGA, updateCardTemplateSaga),
    yield takeEvery(DELETE_CARD_TEMPLATE_SAGA, deleteCardTemplateSaga),
  ]);
}
