import clone from 'clone';
import {
    UPDATE_COST_CENTER
} from './actionsTypes'

const initState = {
  costsCenter: [],
  initialCostCenter: false,
  currentCostCenter: {},
  editableCostCenter: {},
  isNewCostCenter: false,
  enableEditView: false,
  costCenter: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_COST_CENTER: {
      const currentCostCenter = action.costCenter
        ? action.costCenter
        : state.currentCostCenter;

      return {
        ...state,
        costsCenter: action.costsCenter,
        currentCostCenter: clone(currentCostCenter),
        initialCostCenter: true,
        isNewCostCenter: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
