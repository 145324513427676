import http from "../../api";

class AdictionCostDataService {
    getAll() {
        return http({
            'url':'/adictionCost',
            'method':'GET',
        });
    }

    getAdictionCostById(id) {
        return http({
            'url':'/adictionCost/'+id,
            'method':'GET'
        });
    }

    createAdictionCostData(data) {
        return http({
            'url':'/adictionCost',
            'method':'POST',
            'data': data
        });
    }

    updateAdictionCostData(id, data) {
        return http({
            'url':'/adictionCost/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteAdictionCost(id) {
        return http({
            'url':'/adictionCost/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeAdictionCost(id) {
        return http({
            'url':'/adictionCost/'+id,
            'method':'DELETE'
        });
    }
}

export default new AdictionCostDataService();