import http from "../api";

class BillDataService {
    getAll() {
        return http({
            'url':'/bills',
            'method':'GET'
        });
    }

    getBillById(id) {
        return http({
            'url':'/bills/'+id,
            'method':'GET'
        });
    }

    getBillsByClient(id) {
        return http({
            'url':'/bills/client/'+id,
            'method':'GET'
        });
    }

    getPaymentAmountFromBill(id) {
        return http({
            'url':'/bills/payment_amount/'+id,
            'method':'GET'
        });
    }

    getBillsFromActivity(id) {
        return http({
            'url':'/bills/from_activity/'+id,
            'method':'GET'
        });
    }

    getPaymentHistory(id) {
        return http({
            'url':'/bills/payment_history/'+id,
            'method':'GET'
        });
    }

    createBillData(data) {
        return http({
            'url':'/bills',
            'method':'POST',
            'data': data
        });
    }

    registerPayment(id, data) {
        return http({
            'url':'/bills/payment/'+id,
            'method':'POST',
            'data': data
        });
    }

    revertPaymentInCash(id, data) {
        return http({
            'url':'/bills/revert_payment/'+id,
            'method':'POST',
            'data': data
        });
    }

    cancelBill(id) {
        return http({
            'url':'/bills/cancel/'+id,
            'method':'POST'
        });
    }

    updateBillData(id, data) {
        return http({
            'url':'/bills/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteBill(id) {
        return http({
            'url':'/bills/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeBill(id) {
        return http({
            'url':'/bills/'+id,
            'method':'DELETE'
        });
    }

    displayChargePDF(id) {
        return http({
            'url':'/bills/get_cobranca_pdf/'+id,
            'method':'GET',
            'responseType': 'arraybuffer',
            'withCredentials':true
        });
    }
}

export default new BillDataService();