import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_TYPE_GUARANTEE, UPDATE_TYPE_GUARANTEE, UPDATE_TYPE_GUARANTEE_SAGA, DELETE_TYPE_GUARANTEE_SAGA } from './actionsTypes';

import api from '@iso/services/repository/comercialCondition/typeGuarantee';

export function* getTypesGuarantee() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_TYPE_GUARANTEE,
      typesGuarantee: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateTypeGuaranteeSaga({ typesGuarantee, typeGuarantee }) {
  try {
    if(typeof typeGuarantee !== 'undefined' ){
      var isNew = false;
      const aux = (typeof typeGuarantee._id !== 'undefined') ?
        yield call(api.updateTypeGuaranteeData, typeGuarantee._id, typeGuarantee) :
        yield call(api.createTypeGuaranteeData, typeGuarantee);
      const result = yield call(api.getAll);
      
      if(typeof typeGuarantee._id === 'undefined' ){
        isNew = true;
        typesGuarantee[0] = aux.data
      }

      yield put({
        type: UPDATE_TYPE_GUARANTEE,
        typesGuarantee: result.data,
        typeGuarantee: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteTypeGuaranteeSaga({ typesGuarantee, typeGuarantee }) {
  try {
    if(typeof typeGuarantee !== 'undefined' ){
      yield call(api.softDeleteTypeGuarantee, typeGuarantee);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_TYPE_GUARANTEE,
        typesGuarantee: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_TYPE_GUARANTEE, getTypesGuarantee),
    yield takeEvery(UPDATE_TYPE_GUARANTEE_SAGA, updateTypeGuaranteeSaga),
    yield takeEvery(DELETE_TYPE_GUARANTEE_SAGA, deleteTypeGuaranteeSaga),
  ]);
}
