import clone from 'clone';
import {
    UPDATE_PEOPLE_GROUP
} from './actionsTypes'

const initState = {
  peopleGroups: [],
  initialPeopleGroups: false,
  currentPeopleGroup: {},
  editablePeopleGroup: {},
  isNewPeopleGroup: false,
  enableEditView: false,
  peopleGroup: {
    key: null,
    _id: 0,
    name: '',
    situation: 'Ativo', // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_PEOPLE_GROUP: {
      const currentPeopleGroup = action.peopleGroup
        ? action.peopleGroup
        : state.currentPeopleGroup;

      return {
        ...state,
        peopleGroups: action.peopleGroups,
        currentPeopleGroup: clone(currentPeopleGroup),
        initialPeopleGroups: true,
        isNewPeopleGroup: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
