import clone from 'clone';
import {
    UPDATE_SEGMENT
} from './actionsTypes'

const initState = {
  segments: [],
  initialSegment: false,
  currentSegment: {},
  editableSegment: {},
  isNewSegment: false,
  enableEditView: false,
  segment: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_SEGMENT: {
      const currentSegment = action.segment
        ? action.segment
        : state.currentSegment;

      return {
        ...state,
        segments: action.segments,
        currentSegment: clone(currentSegment),
        initialSegment: true,
        isNewSegment: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
