import http from "../api";

class GoalDataService {
    // getAll() {
    //     return http({
    //         'url':'/goal',
    //         'method':'GET',
    //         // 'responseType': 'json',
    //         // 'params': {
    //         //     'search':'parameter',
    //         // }
    //     });
    // }

    getGoalById(year, month) {
        return http({
            'url':'/goal/'+year+'/'+month,
            'method':'GET'
        });
    }

    createGoalData(data) {
        return http({
            'url':'/goal',
            'method':'POST',
            'data': data
        });
    }

    updateGoalData(year, month, data) {
        return http({
            'url':'/goal/'+year+'/'+month,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteGoalData(id) {
        return http({
            'url':'/goal/softDelete/'+id,
            'method':'DELETE'
        });
    }

}

export default new GoalDataService();