import clone from 'clone';
import {
    UPDATE_UNIT_MEASUREMENT
} from './actionsTypes'

const initState = {
  unitsMeasurement: [],
  initialUnitsMeasurement: false,
  currentUnitMeasurement: {},
  editableUnitMeasurement: {},
  isNewUnitMeasurement: false,
  enableEditView: false,
  unitMeasurement: {
    key: null,
    _id: 0,
    name: '',
    unitMeasurement: '',
    situation: 'Ativo', // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_UNIT_MEASUREMENT: {
      const currentUnitMeasurement = action.unitMeasurement
        ? action.unitMeasurement
        : state.currentUnitMeasurement;

      return {
        ...state,
        unitsMeasurement: action.unitsMeasurement,
        currentUnitMeasurement: clone(currentUnitMeasurement),
        initialUnitsMeasurement: true,
        isNewUnitMeasurement: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
