import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_PRODUCT, UPDATE_PRODUCT, UPDATE_PRODUCT_SAGA, DELETE_PRODUCT_SAGA } from './actionsTypes';

import api from '@iso/services/repository/product'

export function* getProduct() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_PRODUCT,
      products: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateProductsaga({products, product}) {
  try {
    if(typeof product !== 'undefined' ){
      const result = yield call(api.getAll);
      yield put({
        type: UPDATE_PRODUCT,
        products: result.data,
        product
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteProductsaga({products, product}) {
  try {
    if(typeof product !== 'undefined' ){
      yield call(api.softDeleteProduct, product);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_PRODUCT,
        products: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_PRODUCT, getProduct),
    yield takeEvery(UPDATE_PRODUCT_SAGA, updateProductsaga),
    yield takeEvery(DELETE_PRODUCT_SAGA, deleteProductsaga),
  ]);
}
