import clone from 'clone';
import {
  UPDATE_ATTENDANT
} from './actionsTypes'

const initState = {
  attendants: [],
  initialAttendants: false,
  currentAttendants: {},
  editableAttendants: {},
  isNewAttendants: false,
  enableEditView: false,
  attendant: {
    key: null,
    id: new Date().getTime(),
    name: '',
    situation: 'Ativo', // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_ATTENDANT: {
      const currentAttendants = action.attendant
        ? action.attendant
        : state.currentAttendants;

      return {
        ...state,
        attendants: action.attendants,
        currentAttendants: clone(currentAttendants),
        initialAttendants: true,
        isNewAttendants: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
