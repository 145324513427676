import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_BRAND, UPDATE_BRAND, UPDATE_BRAND_SAGA, DELETE_BRAND_SAGA } from './actionsTypes';

import api from '@iso/services/repository/brand'

export function* getBrands() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_BRAND,
      brands: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateBrandSaga({ brands, brand }) {
  try {
    if(typeof brand !== 'undefined' ){
      var isNew = false;
      const aux = (typeof brand._id !== 'undefined') ?
        yield call(api.updateBrandData, brand._id, brand) :
        yield call(api.createBrandData, brand);
      const result = yield call(api.getAll);
      
      if(typeof brand._id === 'undefined' ){
        isNew = true;
        brands[0] = aux.data
      }

      yield put({
        type: UPDATE_BRAND,
        brands: result.data,
        brand: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteBrandSaga({ brands, brand }) {
  try {
    if(typeof brand !== 'undefined' ){
      yield call(api.softDeleteBrand, brand);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_BRAND,
        brands: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_BRAND, getBrands),
    yield takeEvery(UPDATE_BRAND_SAGA, updateBrandSaga),
    yield takeEvery(DELETE_BRAND_SAGA, deleteBrandSaga),
  ]);
}
