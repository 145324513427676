import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_BILL, UPDATE_BILL, UPDATE_BILL_SAGA, DELETE_BILL_SAGA } from './actionsTypes';

import api from '@iso/services/repository/bill'

export function* getBills() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_BILL,
      bills: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateBillSaga({ bills, bill }) {
  try {
    if(typeof bill !== 'undefined' ){
      var isNew = false;
      const aux = (typeof bill._id !== 'undefined') ?
        yield call(api.updateBillData, bill._id, bill) :
        yield call(api.createBillData, bill);
      const result = yield call(api.getAll);
      
      if(typeof bill._id === 'undefined' ){
        isNew = true;
        bills[0] = aux.data
      }

      yield put({
        type: UPDATE_BILL,
        bills: result.data,
        bill: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteBillSaga({ bills, bill }) {
  try {
    if(typeof bill !== 'undefined' ){
      yield call(api.softDeleteBill, bill);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_BILL,
        bills: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_BILL, getBills),
    yield takeEvery(UPDATE_BILL_SAGA, updateBillSaga),
    yield takeEvery(DELETE_BILL_SAGA, deleteBillSaga),
  ]);
}
