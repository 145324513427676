import clone from 'clone';
import {
    UPDATE_STAGE
} from './actionsTypes'

const initState = {
  stages: [],
  initialStage: false,
  currentStage: {},
  editableStage: {},
  isNewStage: false,
  enableEditView: false,
  stage: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_STAGE: {
      const currentStage = action.stage
        ? action.stage
        : state.currentStage;

      return {
        ...state,
        stages: action.stages,
        currentStage: clone(currentStage),
        initialStage: false,
        isNewStage: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
