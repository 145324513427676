import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_DEADLINE, UPDATE_DEADLINE, UPDATE_DEADLINE_SAGA, DELETE_DEADLINE_SAGA } from './actionsTypes';

import api from '@iso/services/repository/comercialCondition/deadline';

export function* getDeadlines() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_DEADLINE,
      deadlines: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateDeadlineSaga({ deadlines, deadline }) {
  try {
    if(typeof deadline !== 'undefined' ){
      var isNew = false;
      const aux = (typeof deadline._id !== 'undefined') ?
        yield call(api.updateDeadlineData, deadline._id, deadline) :
        yield call(api.createDeadlineData, deadline);
      
      if(typeof deadline._id === 'undefined' ){
        isNew = true;
        deadlines[0] = aux.data
      }

      yield put({
        type: UPDATE_DEADLINE,
        deadlines,
        deadline: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteDeadlineSaga({ deadlines, deadline }) {
  try {
    if(typeof deadline !== 'undefined' ){
      yield call(api.softDeleteDeadline, deadline);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_DEADLINE,
        deadlines: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_DEADLINE, getDeadlines),
    yield takeEvery(UPDATE_DEADLINE_SAGA, updateDeadlineSaga),
    yield takeEvery(DELETE_DEADLINE_SAGA, deleteDeadlineSaga),
  ]);
}
