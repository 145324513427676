import clone from 'clone';
import {
    UPDATE_ADICTION_BUDGET
} from './actionsTypes'

const initState = {
  adictionBudgets: [],
  initialAdictionBudgets: false,
  currentAdictionBudget: {},
  editableAdictionBudget: {},
  isNewAdictionBudget: false,
  enableEditView: false,
  adictionBudget: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_ADICTION_BUDGET: {
      const currentAdictionBudget = action.adictionBudget
        ? action.adictionBudget
        : state.currentAdictionBudget;

      return {
        ...state,
        adictionBudgets: action.adictionBudgets,
        currentAdictionBudget: clone(currentAdictionBudget),
        initialAdictionBudgets: true,
        isNewAdictionBudget: false,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
