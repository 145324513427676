import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_CAMPAIGN, UPDATE_CAMPAIGN, UPDATE_CAMPAIGN_SAGA, DELETE_CAMPAIGN_SAGA, START_CAMPAIGN_SAGA, STOP_CAMPAIGN_SAGA } from './actionsTypes';

import api from '@iso/services/repository/campaign'

export function* getCampaigns() {
  try {
    const result = yield call(api.getAll);

    yield put({
      type: UPDATE_CAMPAIGN,
      campaigns: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateCampaignSaga({ campaigns, campaign }) {
  try {
    if(typeof campaign !== 'undefined' ){
      var isNew = false;
      const aux = (typeof campaign._id !== 'undefined') ?
        yield call(api.updateCampaignData, campaign._id, campaign) :
        yield call(api.createCampaignData, campaign);
      const result = yield call(api.getAll);
      
      if(typeof campaign._id === 'undefined' ){
        isNew = true;
        campaigns[0] = aux.data
      }

      yield put({
        type: UPDATE_CAMPAIGN,
        campaigns: result.data,
        campaign: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteCampaignSaga({ campaigns, campaign }) {
  try {
    if(typeof campaign !== 'undefined' ){
      yield call(api.softDeleteCampaign, campaign);
      const result = yield call(api.getAll);
      // console.log(result)

      yield put({
        type: UPDATE_CAMPAIGN,
        campaigns: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* startCampaignSaga({ campaigns, campaign }) {
  try {
    if(typeof campaign !== 'undefined' ){
      yield call(api.startCampaign, campaign);
      const result = yield call(api.getAll);
      // console.log(result)

      yield put({
        type: UPDATE_CAMPAIGN,
        campaigns: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* stopCampaignSaga({ campaigns, campaign }) {
  try {
    if(typeof campaign !== 'undefined' ){
      yield call(api.stopCampaign, campaign);
      const result = yield call(api.getAll);
      // console.log(result)

      yield put({
        type: UPDATE_CAMPAIGN,
        campaigns: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_CAMPAIGN, getCampaigns),
    yield takeEvery(UPDATE_CAMPAIGN_SAGA, updateCampaignSaga),
    yield takeEvery(DELETE_CAMPAIGN_SAGA, deleteCampaignSaga),
    yield takeEvery(START_CAMPAIGN_SAGA, startCampaignSaga),
    yield takeEvery(STOP_CAMPAIGN_SAGA, stopCampaignSaga),
  ]);
}