import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_PEOPLE_GROUP, UPDATE_PEOPLE_GROUP, UPDATE_PEOPLE_GROUP_SAGA, DELETE_PEOPLE_GROUP_SAGA } from './actionsTypes';

import api from '@iso/services/repository/peopleGroup';

export function* getPeopleGroup() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_PEOPLE_GROUP,
      peopleGroups: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updatePeopleGroupSaga({ peopleGroups, peopleGroup }) {
  try {
    if(typeof peopleGroup !== 'undefined' ){
      var isNew = false;
      const aux = (typeof peopleGroup._id !== 'undefined') ?
        yield call(api.updatePeopleGroup, peopleGroup._id, peopleGroup) :
        yield call(api.createPeopleGroup, peopleGroup);
      const result = yield call(api.getAll);

      if(typeof peopleGroup._id === 'undefined' ){
        isNew = true;
        peopleGroups[0] = result.data
      }

      yield put({
        type: UPDATE_PEOPLE_GROUP,
        peopleGroups: result.data,
        peopleGroup: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deletePeopleGroupSaga({ peopleGroups, peopleGroup }) {
  try {
    if(typeof peopleGroup !== 'undefined' ){
      yield call(api.softDeletePeopleGroup, peopleGroup);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_PEOPLE_GROUP,
        peopleGroups: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_PEOPLE_GROUP, getPeopleGroup),
    yield takeEvery(UPDATE_PEOPLE_GROUP_SAGA, updatePeopleGroupSaga),
    yield takeEvery(DELETE_PEOPLE_GROUP_SAGA, deletePeopleGroupSaga)
  ]);
}
