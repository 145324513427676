import http from "../api";

class OrderCategoryDataService {
    getAll() {
        return http({
            'url':'/orderCategory',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getOrderCategoryById(id) {
        return http({
            'url':'/orderCategory/'+id,
            'method':'GET'
        });
    }

    createOrderCategoryData(data) {
        return http({
            'url':'/orderCategory',
            'method':'POST',
            'data': data
        });
    }

    updateOrderCategoryData(id, data) {
        return http({
            'url':'/orderCategory/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteOrderCategory(id) {
        return http({
            'url':'/orderCategory/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeOrderCategory(id) {
        return http({
            'url':'/orderCategory/'+id,
            'method':'DELETE'
        });
    }
}

export default new OrderCategoryDataService();