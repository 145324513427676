import clone from 'clone';
import {
    UPDATE_TYPE_FREIGHT
} from './actionsTypes'

const initState = {
  typesFreight: [],
  initialTypesFreight: false,
  currentTypeFreight: {},
  editableTypeFreight: {},
  isNewTypeFreight: false,
  enableEditView: false,
  typeFreight: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_TYPE_FREIGHT: {
      const currentTypeFreight = action.typeFreight
        ? action.typeFreight
        : state.currentTypeFreight;

      return {
        ...state,
        typesFreight: action.typesFreight,
        currentTypeFreight: clone(currentTypeFreight),
        initialTypesFreight: true,
        isNewTypeFreight: false,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
