import clone from 'clone';
import {
    UPDATE_CONTRACT
} from './actionsTypes'

const initState = {
  contracts: [],
  initialContract: false,
  currentContract: {},
  editableContract: {},
  isNewContract: false,
  enableEditView: false,
  contract: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_CONTRACT: {
      const currentContract = action.contract
        ? action.contract
        : state.currentContract;

      return {
        ...state,
        contracts: action.contracts,
        currentContract: clone(currentContract),
        initialContract: true,
        isNewContract: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
