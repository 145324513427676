import { all, takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADICTION_CONSTANT, UPDATE_ADICTION_CONSTANT, UPDATE_ADICTION_CONSTANT_SAGA, DELETE_ADICTION_CONSTANT_SAGA } from './actionsTypes';

import api from '@iso/services/repository/comercialCondition/adictionConstant'

export function* getAdictionConstants() {
  try {
    const result = yield call(api.getAll);

    yield put({
      type: UPDATE_ADICTION_CONSTANT,
      adictionConstants: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateAdictionConstantsSaga({ adictionConstants, adictionConstant }) {
  try {
    if(typeof adictionConstant !== 'undefined' ){
      yield put({
        type: UPDATE_ADICTION_CONSTANT,
        adictionConstants,
        adictionConstant
      });
    }
  } catch (error) {
    console.error(error);
  }
}


// export function* deleteAdictionConstantsSaga({ adictionConstants, adictionConstant }) {
//   try {
//     if(typeof adictionConstant !== 'undefined' ){
//       // yield call(api.removeAdictionConstant, adictionConstant);
//       yield call(api.softDeleteAdictionConstant, adictionConstant);
//       const result = yield call(api.getAll);
//       console.log(adictionConstants)

//       const newAdictionConstants = adictionConstants.map(inv => {
//         if(inv._id === result.data._id){ 
//           return result.data
//         } else {
//           return inv
//         }
//       });

//       console.log('newAdictionConstants', newAdictionConstants)

//       yield put({
//         type: UPDATE_ADICTION_CONSTANT,
//         adictionConstants: newAdictionConstants,
//         adictionConstant: result.data
//       });
//     }
//   } catch (error) {
//     console.error(error);
//   }
// }

export function* deleteAdictionConstantsSaga({ adictionConstants, adictionConstant }) {
  try {
    if(typeof adictionConstant !== 'undefined' ){
      // yield call(api.removeAdictionConstant, adictionConstant);
      yield call(api.softDeleteAdictionConstant, adictionConstant);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_ADICTION_CONSTANT,
        adictionConstants: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_ADICTION_CONSTANT, getAdictionConstants),
    takeEvery(UPDATE_ADICTION_CONSTANT_SAGA, updateAdictionConstantsSaga),
    takeEvery(DELETE_ADICTION_CONSTANT_SAGA, deleteAdictionConstantsSaga)
  ]);
}
