import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_BOARD, UPDATE_BOARD, UPDATE_BOARD_SAGA, DELETE_BOARD_SAGA } from './actionsTypes';

import api from '@iso/services/repository/board';


function* boardsRenderEffectSaga() {
  try {
    const result = yield call(api.getAll);

    yield put({
      type: UPDATE_BOARD,
      boards: result.data.sort((a, b) => a.order > b.order ? 1 : -1)
    });
  } catch (error) {
    console.error(error);
  }
}

export function* updateBoardSaga({ boards, board }) {
  try {
    if(typeof board !== 'undefined' ){
      var isNew = false;
      const aux = (typeof board._id !== 'undefined') ?
        yield call(api.updateBoardData, board._id, board) :
        yield call(api.createBoardData, board);
      
      if(typeof board._id === 'undefined' ){
        isNew = true;
        boards[0] = aux.data
      }

      yield put({
        type: UPDATE_BOARD,
        boards,
        board: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteBoardSaga({boards, board}) {
  try {
    if(typeof board !== 'undefined' ){
      yield call(api.softDeleteBoard, board);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_BOARD,
        boards: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* scrumBoardSaga() {
  yield all([
    yield takeEvery(GET_BOARD, boardsRenderEffectSaga),
    yield takeEvery(UPDATE_BOARD_SAGA, updateBoardSaga),
    yield takeEvery(DELETE_BOARD_SAGA, deleteBoardSaga),
  ]);
}
