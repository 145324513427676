import { all, takeEvery } from 'redux-saga/effects';
// import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
// import { rsf, db } from '@iso/lib/firebase/firebase';
// import {
//   convertCollectionsSnapshotToMap,
// } from '@iso/lib/firebase/firebase.util';

/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/firestore
 */

// const COLLECTION_NAME = 'boards'; // change your collection
// const ORDER_BY = 'id';
// const ORDER = 'desc';

function* loadFromFirestore() {
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    // takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
  ]);
}
