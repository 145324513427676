import clone from 'clone';
import {
    UPDATE_BRAND
} from './actionsTypes'

const initState = {
  brands: [],
  initialBrand: false,
  currentBrand: {},
  editableBrand: {},
  isNewBrand: false,
  enableEditView: false,
  brand: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_BRAND: {
      const currentBrand = action.brand
        ? action.brand
        : state.currentBrand;

      return {
        ...state,
        brands: action.brands,
        currentBrand: clone(currentBrand),
        initialBrand: true,
        isNewBrand: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
