import http from "../api";

class BankAccountsDataService {
    getAll() {
        return http({
            'url':'/bankAccounts',
            'method':'GET'
        });
    }

    getBankAccountById(id) {
        return http({
            'url':'/bankAccounts/'+id,
            'method':'GET'
        });
    }

    createBankAccountsData(data) {
        return http({
            'url':'/bankAccounts',
            'method':'POST',
            'data': data
        });
    }

    updateBankAccountsData(id, data) {
        return http({
            'url':'/bankAccounts/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteBankAccount(id) {
        return http({
            'url':'/bankAccounts/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeBankAccount(id) {
        return http({
            'url':'/bankAccounts/'+id,
            'method':'DELETE'
        });
    }
}

export default new BankAccountsDataService();