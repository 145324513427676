import http from "../api";

class TemplateDataService {
    getAll() {
        return http({
            'url':'/template',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getTemplateById(id) {
        return http({
            'url':'/template/'+id,
            'method':'GET'
        });
    }

    createTemplateData(data) {
        return http({
            'url':'/template',
            'method':'POST',
            'data': data
        });
    }

    updateTemplateData(id, data) {
        return http({
            'url':'/template/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteTemplate(id) {
        return http({
            'url':'/template/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeTemplate(id) {
        return http({
            'url':'/template/'+id,
            'method':'DELETE'
        });
    }
}

export default new TemplateDataService();