import http from "../api";

class BrandDataService {
    getAll() {
        return http({
            'url':'/brand',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getBrandById(id) {
        return http({
            'url':'/brand/'+id,
            'method':'GET'
        });
    }

    createBrandData(data) {
        return http({
            'url':'/brand',
            'method':'POST',
            'data': data
        });
    }

    updateBrandData(id, data) {
        return http({
            'url':'/brand/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteBrand(id) {
        return http({
            'url':'/brand/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeBrand(id) {
        return http({
            'url':'/brand/'+id,
            'method':'DELETE'
        });
    }
}

export default new BrandDataService();