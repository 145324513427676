import http from "../../api";

class AdictionConstantDataService {
    getAll() {
        return http({
            'url':'/adictionConstant',
            'method':'GET',
        });
    }

    getAdictionConstantById(id) {
        return http({
            'url':'/adictionConstant/'+id,
            'method':'GET'
        });
    }

    createAdictionConstantData(data) {
        return http({
            'url':'/adictionConstant',
            'method':'POST',
            'data': data
        });
    }

    updateAdictionConstantData(id, data) {
        return http({
            'url':'/adictionConstant/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteAdictionConstant(id) {
        return http({
            'url':'/adictionConstant/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeAdictionConstant(id) {
        return http({
            'url':'/adictionConstant/'+id,
            'method':'DELETE'
        });
    }
}

export default new AdictionConstantDataService();