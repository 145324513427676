import { getBaseUrl } from "../../library/helpers/utility";
import http from "../api";
import axios from "axios";

class BoardDataService {
    getAll() {
        return http({
            'url':'/boards',
            'method':'GET',
        });
    }

    getAllIntegrations() {
        return http({
            'url':'/boards/integrations',
            'method':'GET',
        });
    }

    getAllBasic() {
        return http({
            'url':'/boards/basic',
            'method':'GET',
        });
    }

    getAllInfo() {
        return http({
            'url':'/boards/allInfo',
            'method':'GET',
        });
    }

    getPeopleByAll(parameter) {
        return http({
            'url':'/boards/findPeople/'+parameter,
            'method':'GET'
        });
    }

    getBoardByParameter(what, parameter) {
        return http({
            'url':'/boards/find/'+what+'/'+parameter,
            'method':'GET'
        });
    }

    getBoardById(id) {
        return http({
            'url':'/boards/one/'+id,
            'method':'GET'
        });
    }
    
    getBoardData(id, what, extra) {
        var url = '/boards/data/'+id+'/'+what;
        if(typeof extra != 'undefined') {
            url += '/'+extra;
        }
        return http({
            'url':url,
            'method':'GET'
        });
    }

    createBoardData(data) {
        return http({
            'url':'/boards',
            'method':'POST',
            'data': data
        });
    }

    saveTaskStageChange(data) {
        return http({
            'url':'/boards/save_state_change',
            'method':'POST',
            'data': data
        });
    }

    saveIntegrationsOrder(data) {
        return http({
            'url':'/boards/save_integrations_order',
            'method':'POST',
            'data': data
        });
    }

    handleDeleteIntegration(data) {
        return http({
            'url':'/boards/handle_delete_integration',
            'method':'POST',
            'data': data
        });
    }

    testFinanceActivity(data) {
        return http({
            'url':'/boards/test_finance_activity',
            'method':'POST',
            'data': data
        });
    }

    saveBoardsOrder(data) {
        return http({
            'url':'/boards/save_boards_order',
            'method':'POST',
            'data': data
        });
    }

    sendActivityNewOccurrences(data) {
        return http({
            'url':'/boards/save_activity_occurrences',
            'method':'POST',
            'data': data
        });
    }

    saveSchedule(data) {
        return http({
            'url':'/boards/save_schedule',
            'method':'POST',
            'data': data
        });
    }

    doSearchActivities(data) {
        return http({
            'url':'/boards/search_activities',
            'method':'POST',
            'data': data
        });
    }

    makeIntegration(data) {
        return http({
            'url':'/boards/make_integration',
            'method':'POST',
            'data': data
        });
    }

    createOccurrence(data) {
        return http({
            'url':'/boards/create_occurrence',
            'method':'POST',
            'data': data
        });
    }

    deleteAttachment(data) {
        return http({
            'url':'/boards/delete_attachment',
            'method':'POST',
            'data': data
        });
    }

    updateBoardData(id, data) {
        return http({
            'url':'/boards/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteBoard(id) {
        return http({
            'url':'/boards/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeBoard(id) {
        return http({
            'url':'/boards/'+id,
            'method':'DELETE'
        });
    }
}

export default new BoardDataService();