import clone from 'clone';
import { newPlan } from './config';
import {
    UPDATE_PLAN,
    SELECT_CURRENT_PLAN
} from './actionsTypes'

const initState = {
  plans: [],
  initialPlans: false,
  currentPlan: {},
  editablePlan: {},
  isNewPlan: false,
  enableEditView: false,
  plan: {
    key: null,
    _id: 0,
    name: '',
    qtdUsuarios: '',
    valor: '',
    situation: 'Ativo', // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_PLAN: {
      const currentPlan = action.plan
        ? action.plan
        : state.currentPlan;

      return {
        ...state,
        plans: action.plans,
        currentPlan: clone(currentPlan),
        initialPlans: true,
        isNewPlan: false,
        enableEditView: false,
      };
    }
    case SELECT_CURRENT_PLAN: {
      const plans = state.plans;
      const index = plans.findIndex(plan => plan._id === action.id);
      const isNewPlan = index === -1;
      const currentPlan = isNewPlan
        ? {
            id: action.id,
            number: `#${action.id}`,
            key: action.id,
            ...newPlan,
          }
        : plans[index];
      const enableEditView = isNewPlan;
      return {
        ...state,
        currentPlan,
        isNewPlan,
        enableEditView,
        editablePlan: clone(currentPlan),
      };
    }
    default:
      return state;
  }
}
