import clone from 'clone';
import {
    UPDATE_PRODUCT_GRID
} from './actionsTypes'

const initState = {
  productGrids: [],
  initialProductGrids: false,
  currentProductGrid: {},
  editableProductGrid: {},
  isNewProductGrid: false,
  enableEditView: false,
  productGrid: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_PRODUCT_GRID: {
      const currentProductGrid = action.productGrid
        ? action.productGrid
        : state.currentProductGrid;

      return {
        ...state,
        productGrids: action.productGrids,
        currentProductGrid: clone(currentProductGrid),
        initialProductGrids: true,
        isNewProductGrid: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
