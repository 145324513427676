import http from "../../api";

class PaymentFormDataService {
    getAll() {
        return http({
            'url':'/paymentForm',
            'method':'GET',
        });
    }

    getAllSimple() {
        return http({
            'url':'/paymentForm/simple',
            'method':'GET',
        });
    }

    getPaymentFormById(id) {
        return http({
            'url':'/paymentForm/'+id,
            'method':'GET'
        });
    }

    createPaymentFormData(data) {
        return http({
            'url':'/paymentForm',
            'method':'POST',
            'data': data
        });
    }

    updatePaymentFormData(id, data) {
        return http({
            'url':'/paymentForm/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeletePaymentForm(id) {
        return http({
            'url':'/paymentForm/softDelete/'+id,
            'method':'PUT'
        });
    }

    removePaymentForm(id) {
        return http({
            'url':'/paymentForm/'+id,
            'method':'DELETE'
        });
    }
}

export default new PaymentFormDataService();