import clone from 'clone';
import {
    UPDATE_PAYMENT_FORM
} from './actionsTypes'

const initState = {
  paymentForms: [],
  initialPaymentsForm: false,
  currentPaymentForm: {},
  editablePaymentForm: {},
  isNewPaymentForm: false,
  enableEditView: false,
  paymentForm: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_PAYMENT_FORM: {
      const currentPaymentForm = action.paymentForm
      ? action.paymentForm
      : state.currentPaymentForm;

      return {
        ...state,
        paymentForms: action.paymentForms,
        currentPaymentForm: clone(currentPaymentForm),
        initialPaymentsForm: true,
        isNewPaymentForm: false,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
