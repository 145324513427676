import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_PAYMENT_FORM, UPDATE_PAYMENT_FORM, UPDATE_PAYMENT_FORM_SAGA, DELETE_PAYMENT_FORM_SAGA } from './actionsTypes';

import api from '@iso/services/repository/comercialCondition/paymentForm';

export function* getPaymentsForm() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_PAYMENT_FORM,
      paymentForms: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updatePaymentFormSaga({ paymentForms, paymentForm }) {
  try {
    if(typeof paymentForm !== 'undefined' ){
      var isNew = false;
      const aux = (typeof paymentForm._id !== 'undefined') ?
        yield call(api.updatePaymentFormData, paymentForm._id, paymentForm) :
        yield call(api.createPaymentFormData, paymentForm);
      const result = yield call(api.getAll);

      if(typeof paymentForm._id === 'undefined' ){
        isNew = true;
        paymentForms[0] = aux.data
      }

      yield put({
        type: UPDATE_PAYMENT_FORM,
        paymentForms: result.data,
        paymentForm: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deletePaymentFormSaga({ paymentForms, paymentForm }) {
  try {
    if(typeof paymentForm !== 'undefined' ){
      yield call(api.softDeletePaymentForm, paymentForm);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_PAYMENT_FORM,
        paymentForms: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_PAYMENT_FORM, getPaymentsForm),
    yield takeEvery(UPDATE_PAYMENT_FORM_SAGA, updatePaymentFormSaga),
    yield takeEvery(DELETE_PAYMENT_FORM_SAGA, deletePaymentFormSaga),
  ]);
}
