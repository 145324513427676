import clone from 'clone';
import {
    UPDATE_MESSAGING_CAMPAIGN
} from './actionsTypes'

const initState = {
  messagingCampaigns: [],
  initialMessagingCampaign: false,
  currentMESSAGING_CAMPAIGN: {},
  editableMESSAGING_CAMPAIGN: {},
  isNewMESSAGING_CAMPAIGN: false,
  enableEditView: false,
  messaging_campaign: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_MESSAGING_CAMPAIGN: {
      const currentMESSAGING_CAMPAIGN = action.messaging_campaign
        ? action.messaging_campaign
        : state.currentMESSAGING_CAMPAIGN;

      return {
        ...state,
        messagingCampaigns: action.messagingCampaigns,
        currentMESSAGING_CAMPAIGN: clone(currentMESSAGING_CAMPAIGN),
        initialMessagingCampaign: true,
        isNewMESSAGING_CAMPAIGN: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
