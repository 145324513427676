import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { clearToken } from '@iso/lib/helpers/utility';
import { LOGOUT, LOGIN_ERROR, LOGIN_SUCCESS, CHECK_AUTHORIZATION, LOGIN_REQUEST } from './actionsType';

const history = createBrowserHistory();
const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield clearToken();
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    const { token } = payload;
    if (token) {
      yield put({
        type: LOGIN_SUCCESS,
        token: token,
        profile: 'Profile',
      });
    } else {
      if (fakeApiCall) {
        yield put({
          type: LOGIN_SUCCESS,
          token: 'secret token',
          profile: 'Profile',
        });
      } else {
        yield put({ type: LOGIN_ERROR });
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(LOGIN_SUCCESS, function*(payload) {
    yield sessionStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(LOGOUT, function*() {
    localStorage.clear();
    sessionStorage.clear();
    yield clearToken();
    history.push('/');
  });
}

export function* checkSession() {
  yield takeEvery(CHECK_AUTHORIZATION, function* () {
    // try {
    //   const tenant = localStorage.getItem("tenantId");
    //   const response = (tenant) ? yield call(api.checkSession) : {data:{success: false}};

    //   // Verificar a resposta da API e atualizar o estado do Redux em consequência
    //   if (response.data.success) {
    //     yield put({
    //       type: LOGIN_SUCCESS,
    //       token: response.data.token,
    //       profile: 'Profile',
    //     });
    //   } else {
    //     yield put({ type: LOGOUT });
    //   }
    // } catch (error) {
    //   yield put({ type: LOGOUT });
    // }
    
    // Como estava
    const loc = sessionStorage.getItem("user");
    const ses = sessionStorage.getItem("user");

    const token = (loc) ? loc : ses;
    if (token) {
      yield put({
        type: LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }

  });
}

export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(loginError),
    fork(logout),
    fork(checkSession),
  ]);
}
