import clone from 'clone';
import { newBillPay } from './config';
import {
    UPDATE_BILLS_PAY,
    SELECT_CURRENT_BILLS_PAY
} from './actionsTypes'

const initState = {
  billsPay: [],
  initialBillsPay: false,
  currentBillPay: {},
  editableBillPay: {},
  isNewBillPay: false,
  enableEditView: false,
  billPay: {
    key: null,
    _id: 0,
    fornecedor: "",
    history: "",
    emission: "",
    maturity: "",
    value: "",
    balance: "",
    category: "",
    occurrence: "",
    dayMaturity: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_BILLS_PAY: {
      const currentBillPay = action.billPay
        ? action.billPay
        : state.currentBillPay;

      return {
        ...state,
        billsPay: action.billsPay,
        currentBillPay: clone(currentBillPay),
        initialBillsPay: true,
        isNewBillPay: false,
        enableEditView: false,
      };
    }
    case SELECT_CURRENT_BILLS_PAY: {
      const billsPay = state.billsPay;
      const index = billsPay.findIndex(billPay => billPay._id === action.id);
      const isNewBillPay = index === -1;
      const currentBillPay = isNewBillPay
        ? {
            id: action.id,
            number: `#${action.id}`,
            key: action.id,
            ...newBillPay,
          }
        : billsPay[index];
      const enableEditView = isNewBillPay;
      return {
        ...state,
        currentBillPay,
        isNewBillPay,
        enableEditView,
        editableBillPay: clone(currentBillPay),
      };
    }
    default:
      return state;
  }
}
