import { all, takeEvery, put } from 'redux-saga/effects';
import { GET_TEMPLATE_FORM, UPDATE_TEMPLATE_FORM } from './actionsTypes';

// import api from '@iso/services/repository/peopleGroup'

export function* getTemplatesForm() {
  try {
    // const result = yield call(api.getAll);
    const result = [
      {
        _id: 'b9c11952-f77d-4d4b-814e-0861db1458d5',
        departamento: {
          _id: 'b9c11952-f77d-4d2b-814e-0761db1458d4',
          nome: 'Comercial'
        },
        blocks: [
          {
            nome: 'Etapa',
            width: '12'
          },
          {
            nome: 'Produtos',
            width: '12'
          },
          {
            nome: 'Contrato',
            width: '12'
          }
        ],
        situation: "Ativo",
        createdAt: '2022-12-19T17:18:17.751Z',
        updatedAt: '2023-02-13T16:05:42.786Z',
      },
      {
        _id: 'b9c11952-f77d-4d4b-814e-0871db1458d5',
        departamento: {
          _id: 'b9c11952-f78d-4d2b-814e-0761db1459d4',
          nome: 'PCP'
        },
        blocks: [
          {
            nome: 'Etapa',
            width: '12'
          },
          {
            nome: 'Produtos',
            width: '12'
          },
          {
            nome: 'Ocorrencia',
            width: '24'
          }
        ],
        situation: "Ativo",
        createdAt: '2022-12-19T17:18:17.751Z',
        updatedAt: '2023-02-13T16:05:42.786Z',
      }
    ];
    yield put({
      type: UPDATE_TEMPLATE_FORM,
      templatesForm: result
    })
  } catch (error) {
    console.error(error);
  }
}


export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_TEMPLATE_FORM, getTemplatesForm),
  ]);
}
