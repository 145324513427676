import { all, takeEvery, call, put } from 'redux-saga/effects';
import { GET_PEOPLE, GET_ALL_PEOPLE, UPDATE_PEOPLE, UPDATE_PEOPLE_SAGA, DELETE_PEOPLE_SAGA } from './actionsTypes';

import api from '@iso/services/repository/people'

export function* getPeople() {
  try {
    const result = yield call(api.getSmallAll);
    // const result = yield call(api.getAll);

    yield put({
      type: UPDATE_PEOPLE,
      people: result.data
    })
  } catch (error) {
    console.error(error);
  }
}
export function* getAllPeople() {
  try {
    // const result = yield call(api.getSmallAll);
    const result = yield call(api.getAll);

    yield put({
      type: UPDATE_PEOPLE,
      people: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updatePeopleSaga({ people, person }) {
  try {
    if(typeof person !== 'undefined' ){
      yield put({
        type: UPDATE_PEOPLE,
        people,
        person
      });
    }
  } catch (error) {
    console.error(error);
  }
}


// export function* deletePeopleSaga({ people, person }) {
//   try {
//     if(typeof person !== 'undefined' ){
//       // yield call(api.removePerson, person);
//       yield call(api.softDeletePerson, person);
//       const result = yield call(api.getAll);
//       console.log(people)

//       const newPeople = people.map(inv => {
//         if(inv._id === result.data._id){ 
//           return result.data
//         } else {
//           return inv
//         }
//       });

//       console.log('newPeople', newPeople)

//       yield put({
//         type: UPDATE_PEOPLE,
//         people: newPeople,
//         person: result.data
//       });
//     }
//   } catch (error) {
//     console.error(error);
//   }
// }

export function* deletePeopleSaga({ people, person }) {
  try {
    if(typeof person !== 'undefined' ){
      // yield call(api.removePerson, person);
      yield call(api.softDeletePerson, person);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_PEOPLE,
        people: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_PEOPLE, getPeople),
    takeEvery(GET_ALL_PEOPLE, getAllPeople),
    takeEvery(UPDATE_PEOPLE_SAGA, updatePeopleSaga),
    takeEvery(DELETE_PEOPLE_SAGA, deletePeopleSaga)
  ]);
}
