import clone from 'clone';
import {
  UPDATE_ADICTION_CONSTANT
} from './actionsTypes'

const initState = {
  adictionConstants: [],
  initialAdictionConstants: false,
  currentAdictionConstants: {},
  editableAdictionConstants: {},
  isNewAdictionConstants: false,
  enableEditView: false,
  adictionConstant: {
    key: null,
    id: new Date().getTime(),
    name: '',
    fantasyName: '',
    type: 'pf',
    fone: '',
    email: '',
    cpf_cnpj: '',
    rg_ie: '',
    group: '',
    segment: '',
    cep: '',
    adress: '',
    numberHouse: '',
    district: '',
    complement: '',
    city: '',
    country: '',
    situation: 'ativo', // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_ADICTION_CONSTANT: {
      const currentAdictionConstants = action.adictionConstant
        ? action.adictionConstant
        : state.currentAdictionConstants;

      return {
        ...state,
        adictionConstants: action.adictionConstants,
        currentAdictionConstants: clone(currentAdictionConstants),
        initialAdictionConstants: true,
        isNewAdictionConstants: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
