import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_COST_CENTER, UPDATE_COST_CENTER, UPDATE_COST_CENTER_SAGA, DELETE_COST_CENTER_SAGA } from './actionsTypes';

import api from '@iso/services/repository/costCenter'

export function* getCostsCenter() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_COST_CENTER,
      costsCenter: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateCostCenterSaga({ costsCenter, costCenter }) {
  try {
    if(typeof costCenter !== 'undefined' ){
      var isNew = false;
      const aux = (typeof costCenter._id !== 'undefined') ?
        yield call(api.updateCostCenterData, costCenter._id, costCenter) :
        yield call(api.createCostCenterData, costCenter);
      const result = yield call(api.getAll);
      
      if(typeof costCenter._id === 'undefined' ){
        isNew = true;
        costsCenter[0] = aux.data
      }

      yield put({
        type: UPDATE_COST_CENTER,
        costsCenter: result.data,
        costCenter: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteCostCenterSaga({ costsCenter, costCenter }) {
  try {
    if(typeof costCenter !== 'undefined' ){
      yield call(api.softDeleteCostCenter, costCenter);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_COST_CENTER,
        costsCenter: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_COST_CENTER, getCostsCenter),
    yield takeEvery(UPDATE_COST_CENTER_SAGA, updateCostCenterSaga),
    yield takeEvery(DELETE_COST_CENTER_SAGA, deleteCostCenterSaga),
  ]);
}
