import clone from 'clone';
import {
    UPDATE_DEADLINE
} from './actionsTypes'

const initState = {
  deadlines: [],
  initialDeadlines: false,
  currentDeadline: {},
  editableDeadline: {},
  isNewDeadline: false,
  enableEditView: false,
  deadline: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_DEADLINE: {
      const currentDeadline = action.deadline
        ? action.deadline
        : state.currentDeadline;

      return {
        ...state,
        deadlines: action.deadlines,
        currentDeadline: clone(currentDeadline),
        initialDeadlines: true,
        isNewDeadline: false,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
