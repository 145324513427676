import clone from 'clone';
import {
    UPDATE_TYPE_GUARANTEE
} from './actionsTypes'

const initState = {
  typesGuarantee: [],
  initialTypesGuarantee: false,
  currentTypeGuarantee: {},
  editableTypeGuarantee: {},
  isNewTypeGuarantee: false,
  enableEditView: false,
  typeGuarantee: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_TYPE_GUARANTEE: {
      const currentTypeGuarantee = action.typeGuarantee
        ? action.typeGuarantee
        : state.currentTypeGuarantee;

      return {
        ...state,
        typesGuarantee: action.typesGuarantee,
        currentTypeGuarantee: clone(currentTypeGuarantee),
        initialTypesGuarantee: true,
        isNewTypeGuarantee: false,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
