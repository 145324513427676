import http from "../api";

class SegmentDataService {
    getAll() {
        return http({
            'url':'/segment',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getSegmentById(id) {
        return http({
            'url':'/segment/'+id,
            'method':'GET'
        });
    }

    createSegmentData(data) {
        return http({
            'url':'/segment',
            'method':'POST',
            'data': data
        });
    }

    updateSegmentData(id, data) {
        return http({
            'url':'/segment/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteSegment(id) {
        return http({
            'url':'/segment/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeSegment(id) {
        return http({
            'url':'/segment/'+id,
            'method':'DELETE'
        });
    }
}

export default new SegmentDataService();