import { all, takeEvery, put } from 'redux-saga/effects';
import { GET_BILLS_RECEIVE, UPDATE_BILLS_RECEIVE } from './actionsTypes';

// import api from '@iso/services/repository/peopleGroup'

export function* getBillsReceive() {
  try {
    // const result = yield call(api.getAll);
    const result = [
      {
        _id: 'b9c11952-f77d-4d3b-814e-0761db1458d5',
        fornecedor: "Roma Contabilidade LTDA",
        history: "Honorarios Contabilidade",
        emission: "22/01/2023",
        maturity: "25/01/2023",
        value: "75",
        netValue: "70",
        balance: "75",
        received: "0",
        category: "3.1 Custo Administrativo",
        installments: "3",
        occurrence: "Mensal",
        dayMaturity: "25",
        situation: "Ativo",
        createdAt: '2022-12-19T17:18:17.751Z',
        updatedAt: '2023-02-13T16:05:42.786Z',
      },
      {
        _id: 'b9c11952-f77d-4d4b-814e-0761db1458d5',
        fornecedor: "Roma Contabilidade LTDA",
        history: "Honorarios Contabilidade",
        emission: "22/01/2023",
        maturity: "25/01/2023",
        value: "60",
        netValue: "55",
        balance: "60",
        received: "0",
        category: "3.1 Custo Administrativo",
        installments: "4",
        occurrence: "Mensal",
        dayMaturity: "20",
        situation: "Ativo",
        createdAt: '2022-12-19T17:18:17.751Z',
        updatedAt: '2023-02-13T16:05:42.786Z',
      }
    ];
    yield put({
      type: UPDATE_BILLS_RECEIVE,
      billsReceive: result
    })
  } catch (error) {
    console.error(error);
  }
}


export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_BILLS_RECEIVE, getBillsReceive),
  ]);
}
