import clone from 'clone';
import { newTemplateForm } from './config';
import {
    UPDATE_TEMPLATE_FORM,
    SELECT_CURRENT_TEMPLATE_FORM
} from './actionsTypes'

const initState = {
  templatesForm: [],
  initialTemplatesForm: false,
  currentTemplateForm: {},
  editableTemplateForm: {},
  isNewTemplateForm: false,
  enableEditView: false,
  templateForm: {
    key: null,
    _id: 0,
    departamento: {},
    block: [],
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_TEMPLATE_FORM: {
      const currentTemplateForm = action.templateForm
        ? action.templateForm
        : state.currentTemplateForm;

      return {
        ...state,
        templatesForm: action.templatesForm,
        currentTemplateForm: clone(currentTemplateForm),
        initialTemplatesForm: true,
        isNewTemplateForm: false,
        enableEditView: false,
      };
    }
    case SELECT_CURRENT_TEMPLATE_FORM: {
      const templatesForm = state.templatesForm;
      const index = templatesForm.findIndex(templateForm => templateForm._id === action.id);
      const isNewTemplateForm = index === -1;
      const currentTemplateForm = isNewTemplateForm
        ? {
            id: action.id,
            number: `#${action.id}`,
            key: action.id,
            ...newTemplateForm,
          }
        : templatesForm[index];
      const enableEditView = isNewTemplateForm;
      return {
        ...state,
        currentTemplateForm,
        isNewTemplateForm,
        enableEditView,
        editableTemplateForm: clone(currentTemplateForm),
      };
    }
    default:
      return state;
  }
}
