import clone from 'clone';
import {
    UPDATE_USER
} from './actionsTypes'

const initState = {
  users: [],
  initialUser: false,
  currentUser: {},
  editableUser: {},
  isNewUser: false,
  enableEditView: false,
  user: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_USER: {
      const currentUser = action.user
        ? action.user
        : state.currentUser;

      return {
        ...state,
        users: action.users,
        currentUser: clone(currentUser),
        initialUser: true,
        isNewUser: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
