import clone from 'clone';
import { UPDATE_FINANCE_CATEGORY } from './actionsTypes'

const initState = {
  financesCategory: [],
  initialFinanceCategory: false,
  currentFinanceCategory: {},
  editableFinanceCategory: {},
  isNewFinanceCategory: false,
  enableEditView: false,
  financeCategory: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_FINANCE_CATEGORY: {
      const currentFinanceCategory = action.financeCategory
        ? action.financeCategory
        : state.currentFinanceCategory;
      return {
        ...state,
        financesCategory: action.financesCategory,
        currentFinanceCategory: clone(currentFinanceCategory),
        initialFinanceCategory: true,
        isNewFinanceCategory: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
