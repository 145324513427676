import antd from 'antd/lib/locale-provider/pt_BR';
import ptMessages from '../locales/pt_BR.json';

const PtLang = {
  messages: {
    ...ptMessages,
  },
  antd: antd,
  locale: 'pt-BR',
};
export default PtLang;
