import clone from 'clone';
import {
    UPDATE_GOAL
} from './actionsTypes'

const initState = {
  goals: [],
  initialGoal: false,
  currentGoal: {},
  editableGoal: {},
  isNewGoal: false,
  enableEditView: false,
  goal: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_GOAL: {
      const currentGoal = action.goal
        ? action.goal
        : state.currentGoal;

      return {
        ...state,
        goals: action.goals,
        currentGoal: clone(currentGoal),
        initialGoal: true,
        isNewGoal: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
