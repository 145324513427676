import http from "../../api";

class AdictionBudgetDataService {
    getAll() {
        return http({
            'url':'/adictionBudget',
            'method':'GET',
        });
    }

    getAdictionBudgetById(id) {
        return http({
            'url':'/adictionBudget/'+id,
            'method':'GET'
        });
    }

    createAdictionBudgetData(data) {
        return http({
            'url':'/adictionBudget',
            'method':'POST',
            'data': data
        });
    }

    updateAdictionBudgetData(id, data) {
        return http({
            'url':'/adictionBudget/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteAdictionBudget(id) {
        return http({
            'url':'/adictionBudget/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeAdictionBudget(id) {
        return http({
            'url':'/adictionBudget/'+id,
            'method':'DELETE'
        });
    }
}

export default new AdictionBudgetDataService();