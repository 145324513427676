import http from "../../api";

class FreightTypeDataService {
    getAll() {
        return http({
            'url':'/freightType',
            'method':'GET',
        });
    }

    getTypeFreightById(id) {
        return http({
            'url':'/freightType/'+id,
            'method':'GET'
        });
    }

    createTypeFreightData(data) {
        return http({
            'url':'/freightType',
            'method':'POST',
            'data': data
        });
    }

    updateTypeFreightData(id, data) {
        return http({
            'url':'/freightType/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteTypeFreight(id) {
        return http({
            'url':'/freightType/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeTypeFreight(id) {
        return http({
            'url':'/freightType/'+id,
            'method':'DELETE'
        });
    }
}

export default new FreightTypeDataService();