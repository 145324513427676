import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_PRODUCT_GRID, UPDATE_PRODUCT_GRID, UPDATE_PRODUCT_GRID_SAGA, DELETE_PRODUCT_GRID_SAGA } from './actionsTypes';

import api from '@iso/services/repository/productGrid';

export function* getProductGrids() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_PRODUCT_GRID,
      productGrids: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateProductGridSaga({ productGrids, productGrid }) {
  try {
    if(typeof productGrid !== 'undefined' ){
      var isNew = false;
      const aux = (typeof productGrid._id !== 'undefined') ?
        yield call(api.updateProductGridData, productGrid._id, productGrid) :
        yield call(api.createProductGridData, productGrid);
      const result = yield call(api.getAll);
      
      if(typeof productGrid._id === 'undefined' ){
        isNew = true;
        productGrids[0] = aux.data
      }

      yield put({
        type: UPDATE_PRODUCT_GRID,
        productGrids: result.data,
        productGrid: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteProductGridSaga({ productGrids, productGrid }) {
  try {
    if(typeof productGrid !== 'undefined' ){
      yield call(api.softDeleteProductGrid, productGrid);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_PRODUCT_GRID,
        productGrids: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_PRODUCT_GRID, getProductGrids),
    yield takeEvery(UPDATE_PRODUCT_GRID_SAGA, updateProductGridSaga),
    yield takeEvery(DELETE_PRODUCT_GRID_SAGA, deleteProductGridSaga),
  ]);
}
