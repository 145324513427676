import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_FINANCE_CATEGORY, UPDATE_FINANCE_CATEGORY, UPDATE_FINANCE_CATEGORY_SAGA, DELETE_FINANCE_CATEGORY_SAGA } from './actionsTypes';

import api from '@iso/services/repository/financeCategory'

export function* getFinancesCategory() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_FINANCE_CATEGORY,
      financesCategory: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateFinanceCategorySaga({ financesCategory, financeCategory }) {
  try {
    if(typeof financeCategory !== 'undefined' ){
      var isNew = false;
      const aux = (typeof financeCategory._id !== 'undefined') ?
        yield call(api.updateFinanceCategoryData, financeCategory._id, financeCategory) :
        yield call(api.createFinanceCategoryData, financeCategory);
      const result = yield call(api.getAll);
      
      if(typeof financeCategory._id === 'undefined' ){
        isNew = true;
        financesCategory[0] = aux.data
      }

      yield put({
        type: UPDATE_FINANCE_CATEGORY,
        financesCategory: result.data,
        financeCategory: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteFinanceCategorySaga({ financesCategory, financeCategory }) {
  try {
    if(typeof financeCategory !== 'undefined' ){
      yield call(api.softDeleteFinanceCategory, financeCategory);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_FINANCE_CATEGORY,
        financesCategory: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_FINANCE_CATEGORY, getFinancesCategory),
    yield takeEvery(UPDATE_FINANCE_CATEGORY_SAGA, updateFinanceCategorySaga),
    yield takeEvery(DELETE_FINANCE_CATEGORY_SAGA, deleteFinanceCategorySaga),
  ]);
}
