import clone from 'clone';
import { UPDATE_PRODUCT_CATEGORY } from './actionsTypes'

const initState = {
  productsCategory: [],
  initialProductCategory: false,
  currentProductCategory: {},
  editableProductCategory: {},
  isNewProductCategory: false,
  enableEditView: false,
  productCategory: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_PRODUCT_CATEGORY: {
      const currentProductCategory = action.productCategory
        ? action.productCategory
        : state.currentProductCategory;
      return {
        ...state,
        productsCategory: action.productsCategory,
        currentProductCategory: clone(currentProductCategory),
        initialProductCategory: true,
        isNewProductCategory: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
