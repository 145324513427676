import http from "../api";

class CostCenterDataService {
    getAll() {
        return http({
            'url':'/costCenter',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getCostCenterById(id) {
        return http({
            'url':'/costCenter/'+id,
            'method':'GET'
        });
    }

    createCostCenterData(data) {
        return http({
            'url':'/costCenter',
            'method':'POST',
            'data': data
        });
    }

    updateCostCenterData(id, data) {
        return http({
            'url':'/costCenter/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteCostCenter(id) {
        return http({
            'url':'/costCenter/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeCostCenter(id) {
        return http({
            'url':'/costCenter/'+id,
            'method':'DELETE'
        });
    }
}

export default new CostCenterDataService();