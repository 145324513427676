import http from "../../api";

class PaymentConditionDataService {
    getAll() {
        return http({
            'url':'/paymentCondition',
            'method':'GET',
        });
    }

    getAllSimple() {
        return http({
            'url':'/paymentCondition/simple',
            'method':'GET',
        });
    }

    getPaymentConditionById(id) {
        return http({
            'url':'/paymentCondition/'+id,
            'method':'GET'
        });
    }

    createPaymentConditionData(data) {
        return http({
            'url':'/paymentCondition',
            'method':'POST',
            'data': data
        });
    }

    updatePaymentConditionData(id, data) {
        return http({
            'url':'/paymentCondition/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeletePaymentCondition(id) {
        return http({
            'url':'/paymentCondition/softDelete/'+id,
            'method':'PUT'
        });
    }

    removePaymentCondition(id) {
        return http({
            'url':'/paymentCondition/'+id,
            'method':'DELETE'
        });
    }
}

export default new PaymentConditionDataService();