import axios from "axios";
import { getBaseUrl } from "../library/helpers/utility";

axios.defaults.withCredentials = true; 

const api = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    // "Cache-Control": "no-cache",
    "Content-type": "application/json",
    // 'Cache-Control': 'no-store'
  },
});

api.interceptors.request.use(request => {  
  const tenantId = localStorage.getItem('tenantId');
  if (tenantId) {
    request.headers['x-tenant-id'] = tenantId;
    request.withCredentials = true;
  }

  return request;
});

export default api;