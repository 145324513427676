
export const newTemplateForm = {
    fornecedor: "",
    history: "",
    emission: "",
    maturity: "",
    value: "",
    balance: "",
    category: "",
    occurrence: "",
    dayMaturity: "",
    situation: 'Ativo'
};