import clone from 'clone';
import {
    UPDATE_PAYMENT_CONDITION
} from './actionsTypes'

const initState = {
  paymentConditions: [],
  initialPaymentConditions: false,
  currentPaymentCondition: {},
  editablePaymentCondition: {},
  isNewPaymentCondition: false,
  enableEditView: false,
  paymentCondition: {
    key: null,
    _id: 0,
    name: "",
    num_parcela: "0",
    juros: false,
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_PAYMENT_CONDITION: {
      const currentPaymentCondition = action.paymentCondition
        ? action.paymentCondition
        : state.currentPaymentCondition;

      return {
        ...state,
        paymentConditions: action.paymentConditions,
        currentPaymentCondition: clone(currentPaymentCondition),
        initialPaymentConditions: true,
        isNewPaymentCondition: false,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
