import http from "../api";

class ProductOriginDataService {
    getAll() {
        return http({
            'url':'/productOrigin',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getProductOriginById(id) {
        return http({
            'url':'/productOrigin/'+id,
            'method':'GET'
        });
    }

    createProductOriginData(data) {
        return http({
            'url':'/productOrigin',
            'method':'POST',
            'data': data
        });
    }

    updateProductOriginData(id, data) {
        return http({
            'url':'/productOrigin/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteProductOrigin(id) {
        return http({
            'url':'/productOrigin/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeProductOrigin(id) {
        return http({
            'url':'/productOrigin/'+id,
            'method':'DELETE'
        });
    }
}

export default new ProductOriginDataService();