import http from "../api";

class CardTemplateDataService {
    getAll() {
        return http({
            'url':'/cardTemplate',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getCardTemplateById(id) {
        return http({
            'url':'/cardTemplate/'+id,
            'method':'GET'
        });
    }

    createCardTemplateData(data) {
        return http({
            'url':'/cardTemplate',
            'method':'POST',
            'data': data
        });
    }

    updateCardTemplateData(id, data) {
        return http({
            'url':'/cardTemplate/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteCardTemplate(id) {
        return http({
            'url':'/cardTemplate/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeCardTemplate(id) {
        return http({
            'url':'/cardTemplate/'+id,
            'method':'DELETE'
        });
    }
}

export default new CardTemplateDataService();