import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_PRODUCT_CATEGORY, UPDATE_PRODUCT_CATEGORY, UPDATE_PRODUCT_CATEGORY_SAGA, DELETE_PRODUCT_CATEGORY_SAGA } from './actionsTypes';

import api from '@iso/services/repository/productCategory'

export function* getProductsCategory() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_PRODUCT_CATEGORY,
      productsCategory: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateProductCategorySaga({ productsCategory, productCategory }) {
  try {
    if(typeof productCategory !== 'undefined' ){
      var isNew = false;
      const aux = (typeof productCategory._id !== 'undefined') ?
        yield call(api.updateProductCategoryData, productCategory._id, productCategory) :
        yield call(api.createProductCategoryData, productCategory);
      const result = yield call(api.getAll);
      
      if(typeof productCategory._id === 'undefined' ){
        isNew = true;
        productsCategory[0] = aux.data
      }

      yield put({
        type: UPDATE_PRODUCT_CATEGORY,
        productsCategory: result.data,
        productCategory: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteProductCategorySaga({ productsCategory, productCategory }) {
  try {
    if(typeof productCategory !== 'undefined' ){
      yield call(api.softDeleteProductCategory, productCategory);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_PRODUCT_CATEGORY,
        productsCategory: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_PRODUCT_CATEGORY, getProductsCategory),
    yield takeEvery(UPDATE_PRODUCT_CATEGORY_SAGA, updateProductCategorySaga),
    yield takeEvery(DELETE_PRODUCT_CATEGORY_SAGA, deleteProductCategorySaga),
  ]);
}
