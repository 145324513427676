import clone from 'clone';
import {
    UPDATE_CAMPAIGN
} from './actionsTypes'

const initState = {
  campaigns: [],
  initialCampaign: false,
  currentCampaign: {},
  editableCampaign: {},
  isNewCampaign: false,
  enableEditView: false,
  campaign: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_CAMPAIGN: {
      const currentCampaign = action.campaign
        ? action.campaign
        : state.currentCampaign;

      return {
        ...state,
        campaigns: action.campaigns,
        currentCampaign: clone(currentCampaign),
        initialCampaign: true,
        isNewCampaign: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
