import { all } from 'redux-saga/effects';
import mailSagas from '@iso/redux/mail/saga';
import authSagas from '@iso/redux/auth/saga';
import goalSagas from '@iso/redux/goal/sagas';
import taskSagas from '@iso/redux/task/sagas';
import billsSagas from '@iso/redux/bill/sagas';
import brandSagas from '@iso/redux/brand/sagas';
import plansSagas from '@iso/redux/plans/sagas';
import usersSagas from '@iso/redux/users/sagas';
import stagesSagas from '@iso/redux/stage/sagas';
import boardsSagas from '@iso/redux/boards/sagas';
import peopleSagas from '@iso/redux/people/sagas';
import lessonSagas from '@iso/redux/lesson/sagas';
import segmentsSagas from '@iso/redux/segment/sagas';
import billsPaySagas from '@iso/redux/billsPay/sagas';
import deadlineSagas from '@iso/redux/deadline/sagas';
import contractSagas from '@iso/redux/contract/sagas';
import productsSagas from '@iso/redux/products/sagas';
import campaignSagas from '@iso/redux/campaign/sagas';
import packagingSagas from '@iso/redux/packaging/sagas';
import templatesSagas from '@iso/redux/templates/sagas';
import attendantsSagas from '@iso/redux/attendants/sagas';
import recurrenceSagas from '@iso/redux/recurrence/sagas';
import costCenterSagas from '@iso/redux/costCenter/sagas';
import scrumBoardSagas from '@iso/redux/scrumBoard/sagas';
import bankAccountSagas from '@iso/redux/bankAccount/sagas';
import paymentFormSagas from '@iso/redux/paymentForm/sagas';
import typeFreightSagas from '@iso/redux/typeFreight/sagas';
import peopleGroupSagas from '@iso/redux/peopleGroup/sagas';
import billsReceiveSagas from '@iso/redux/billsReceive/sagas';
import cardTemplateSagas from '@iso/redux/cardTemplate/sagas';
import templateFormSagas from '@iso/redux/templateForm/sagas';
import adictionCostSagas from '@iso/redux/adictionCost/sagas';
import typeGuaranteeSagas from '@iso/redux/typeGuarantee/sagas';
import productOriginSagas from '@iso/redux/productOrigin/sagas';
import orderCategorySagas from '@iso/redux/orderCategory/sagas';
import productGridSagas from '@iso/redux/productGrid/sagas';
import adictionBudgetSagas from '@iso/redux/adictionBudget/sagas';
import unitsMeasurementSagas from '@iso/redux/unitMeasurement/sagas';
import financesCategorySagas from '@iso/redux/financeCategory/sagas';
import adictionConstantSagas from '@iso/redux/adictionConstant/sagas';
import productsCategorySagas from '@iso/redux/productsCategory/sagas';
import paymentConditionSagas from '@iso/redux/paymentCondition/sagas';

// import quizSaga from '@iso/redux/quiz/saga';
// import cardsSagas from '@iso/redux/card/saga';
// import chatSagas from '@iso/redux/chat/sagas';
// import notesSagas from '@iso/redux/notes/saga';
// import todosSagas from '@iso/redux/todos/saga';
// import articles from '@iso/redux/articles/sagas';
// import profileSaga from '@iso/redux/profile/saga';
// import investors from '@iso/redux/investors/sagas';
// import contactSagas from '@iso/redux/contacts/saga';
// import invoicesSagas from '@iso/redux/invoice/saga';
// import ecommerceSaga from '@iso/redux/ecommerce/saga';
// import githubSagas from '@iso/redux/githubSearch/sagas';
// import youtubeSearchSagas from '@iso/redux/youtubeSearch/sagas';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    mailSagas(),
    taskSagas(),
    goalSagas(),
    brandSagas(),
    billsSagas(),
    plansSagas(),
    usersSagas(),
    boardsSagas(),
    peopleSagas(),
    stagesSagas(),
    lessonSagas(),
    productsSagas(),
    billsPaySagas(),
    contractSagas(),
    deadlineSagas(),
    segmentsSagas(),
    campaignSagas(),
    packagingSagas(),
    templatesSagas(),
    attendantsSagas(),
    recurrenceSagas(),
    costCenterSagas(),
    scrumBoardSagas(),
    bankAccountSagas(),
    paymentFormSagas(),
    peopleGroupSagas(),
    typeFreightSagas(),
    billsReceiveSagas(),
    cardTemplateSagas(),
    templateFormSagas(),
    adictionCostSagas(),
    productGridSagas(),
    typeGuaranteeSagas(),
    productOriginSagas(),
    orderCategorySagas(),
    adictionBudgetSagas(),
    adictionConstantSagas(),
    financesCategorySagas(),
    unitsMeasurementSagas(),
    productsCategorySagas(),
    paymentConditionSagas(),
    // quizSaga(),
    // articles(),
    // chatSagas(),
    // investors(),
    // cardsSagas(),
    // notesSagas(),
    // todosSagas(),
    // githubSagas(),
    // profileSaga(),
    // contactSagas(),
    // ecommerceSaga(),
    // invoicesSagas(),
    // youtubeSearchSagas(),
  ]);
}
