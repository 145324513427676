import http from "../api";

class CampaignsDataService {
    getAll() {
        return http({
            'url':'/leadsCaptureCustumer/campaign',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getCampaignById(id) {
        return http({
            'url':'/leadsCaptureCustumer/campaign/'+id,
            'method':'GET'
        });
    }

    verifyExecut() {
        return http({
            'url':'/leadsCaptureCustumer/campaign/verifyExecut',
            'method':'GET'
        });
    }

    createCampaignData(data) {
        return http({
            'url':'/leadsCaptureCustumer/campaign',
            'method':'POST',
            'data': data
        });
    }

    startCampaign(id) {
        return http({
            'url':'/leadsCaptureCustumer/campaign/startCampaign/'+id,
            'method':'POST'
        });
    }

    stopCampaign(id) {
        return http({
            'url':'/leadsCaptureCustumer/campaign/stopCampaign/'+id,
            'method':'POST'
        });
    }

    updateCampaignData(id, data) {
        return http({
            'url':'/leadsCaptureCustumer/campaign/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteCampaign(id) {
        return http({
            'url':'/leadsCaptureCustumer/campaign/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeCampaign(id) {
        return http({
            'url':'/leadsCaptureCustumer/campaign/'+id,
            'method':'DELETE'
        });
    }
}

export default new CampaignsDataService();