import http from "../api";

class LessonDataService {
    getAll() {
        return http({
            'url':'/lesson',
            'method':'GET',
        });
    }

    getLessonById(id) {
        return http({
            'url':'/lesson/'+id,
            'method':'GET'
        });
    }

    createLessonData(data) {
        return http({
            'url':'/lesson',
            'method':'POST',
            'data': data
        });
    }

    updateLessonData(id, data) {
        return http({
            'url':'/lesson/'+id,
            'method':'PUT',
            'data': data
        });
    }

    removeLesson(id) {
        return http({
            'url':'/lesson/'+id,
            'method':'DELETE'
        });
    }
}

export default new LessonDataService();