import clone from 'clone';
import {
    UPDATE_TEMPLATE
} from './actionsTypes'

const initState = {
  templates: [],
  initialTemplate: false,
  currentTemplate: {},
  editableTemplate: {},
  isNewTemplate: false,
  enableEditView: false,
  template: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_TEMPLATE: {
      const currentTemplate = action.template
        ? action.template
        : state.currentTemplate;

      return {
        ...state,
        templates: action.templates,
        currentTemplate: clone(currentTemplate),
        initialTemplate: true,
        isNewTemplate: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
