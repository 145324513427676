import http from "../../api";

class DeadlineDataService {
    getAll() {
        return http({
            'url':'/deadline',
            'method':'GET',
        });
    }

    getDeadlineById(id) {
        return http({
            'url':'/deadline/'+id,
            'method':'GET'
        });
    }

    createDeadlineData(data) {
        return http({
            'url':'/deadline',
            'method':'POST',
            'data': data
        });
    }

    updateDeadlineData(id, data) {
        return http({
            'url':'/deadline/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteDeadline(id) {
        return http({
            'url':'/deadline/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeDeadline(id) {
        return http({
            'url':'/deadline/'+id,
            'method':'DELETE'
        });
    }
}

export default new DeadlineDataService();