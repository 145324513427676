import http from "../api";

class ProductCategoryDataService {
    getAll() {
        return http({
            'url':'/productCategory',
            'method':'GET',
        });
    }

    getProductCategoryById(id) {
        return http({
            'url':'/productCategory/'+id,
            'method':'GET'
        });
    }

    createProductCategoryData(data) {
        return http({
            'url':'/productCategory',
            'method':'POST',
            'data': data
        });
    }

    updateProductCategoryData(id, data) {
        return http({
            'url':'/productCategory/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteProductCategory(id) {
        return http({
            'url':'/productCategory/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeProductCategory(id) {
        return http({
            'url':'/productCategory/'+id,
            'method':'DELETE'
        });
    }
}

export default new ProductCategoryDataService();