import clone from 'clone';
import {
    UPDATE_VIDEO_LESSION
} from './actionsTypes'

const initState = {
  lessons: [],
  initialLesson: false,
  currentLesson: {},
  editableLesson: {},
  isNewLesson: false,
  enableEditView: false,
  lesson: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_VIDEO_LESSION: {
      const currentLesson = action.lesson
        ? action.lesson
        : state.currentLesson;

      return {
        ...state,
        lessons: action.lessons,
        currentLesson: clone(currentLesson),
        initialLesson: true,
        isNewLesson: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
