import clone from 'clone';
import {
  UPDATE_CARD_TEMPLATE
} from './actionsTypes'

const initState = {
  cardTemplates: [],
  initialCardTemplates: false,
  currentCardTemplate: {},
  editableCardTemplate: {},
  isNewCardTemplate: false,
  enableEditView: false,
  cardTemplate: {
    key: null,
    _id: 0,
    name: '',
    cardTemplate: '',
    situation: 'Ativo', // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_CARD_TEMPLATE: {
      const currentCardTemplate = action.cardTemplate
        ? action.cardTemplate
        : state.currentCardTemplate;

      return {
        ...state,
        cardTemplates: action.cardTemplates,
        currentCardTemplate: clone(currentCardTemplate),
        initialCardTemplates: true,
        isNewCardTemplate: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
