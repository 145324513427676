import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_PRODUCT_ORIGIN, UPDATE_PRODUCT_ORIGIN, UPDATE_PRODUCT_ORIGIN_SAGA, DELETE_PRODUCT_ORIGIN_SAGA } from './actionsTypes';

import api from '@iso/services/repository/productOrigin';

export function* getProductsOrigin() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_PRODUCT_ORIGIN,
      productsOrigin: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateProductOriginSaga({ productsOrigin, productOrigin }) {
  try {
    if(typeof productOrigin !== 'undefined' ){
      var isNew = false;
      const aux = (typeof productOrigin._id !== 'undefined') ?
        yield call(api.updateProductOriginData, productOrigin._id, productOrigin) :
        yield call(api.createProductOriginData, productOrigin);
      const result = yield call(api.getAll);
      
      if(typeof productOrigin._id === 'undefined' ){
        isNew = true;
        productsOrigin[0] = aux.data
      }

      yield put({
        type: UPDATE_PRODUCT_ORIGIN,
        productsOrigin: result.data,
        productOrigin: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteProductOriginSaga({ productsOrigin, productOrigin }) {
  try {
    if(typeof productOrigin !== 'undefined' ){
      yield call(api.softDeleteProductOrigin, productOrigin);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_PRODUCT_ORIGIN,
        productsOrigin: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_PRODUCT_ORIGIN, getProductsOrigin),
    yield takeEvery(UPDATE_PRODUCT_ORIGIN_SAGA, updateProductOriginSaga),
    yield takeEvery(DELETE_PRODUCT_ORIGIN_SAGA, deleteProductOriginSaga),
  ]);
}
