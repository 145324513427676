import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_UNIT_MEASUREMENT, UPDATE_UNIT_MEASUREMENT, UPDATE_UNIT_MEASUREMENT_SAGA, DELETE_UNIT_MEASUREMENT_SAGA } from './actionsTypes';

import api from '@iso/services/repository/unitMeasurement'

export function* getUnitMeasurement() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_UNIT_MEASUREMENT,
      unitsMeasurement: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateUnitMeasurementSaga({ unitsMeasurement, unitMeasurement }) {
  try {
    if(typeof unitMeasurement !== 'undefined' ){
      var isNew = false;
      const aux = (typeof unitMeasurement._id !== 'undefined') ?
        yield call(api.updateUnitMeasurementData, unitMeasurement._id, unitMeasurement) :
        yield call(api.createUnitMeasurementData, unitMeasurement);
      const result = yield call(api.getAll);
      
      if(typeof unitMeasurement._id === 'undefined' ){
        isNew = true;
        unitsMeasurement[0] = aux.data
      }

      yield put({
        type: UPDATE_UNIT_MEASUREMENT,
        unitsMeasurement: result.data,
        unitMeasurement: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteUnitMeasurementSaga({ unitsMeasurement, unitMeasurement }) {
  try {
    if(typeof unitMeasurement !== 'undefined' ){
      yield call(api.softDeleteUnitMeasurement, unitMeasurement);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_UNIT_MEASUREMENT,
        unitsMeasurement: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_UNIT_MEASUREMENT, getUnitMeasurement),
    yield takeEvery(UPDATE_UNIT_MEASUREMENT_SAGA, updateUnitMeasurementSaga),
    yield takeEvery(DELETE_UNIT_MEASUREMENT_SAGA, deleteUnitMeasurementSaga),
  ]);
}
