import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_ORDER_CATEGORY, UPDATE_ORDER_CATEGORY, UPDATE_ORDER_CATEGORY_SAGA, DELETE_ORDER_CATEGORY_SAGA } from './actionsTypes';

import api from '@iso/services/repository/orderCategory';

export function* getOrdersCategory() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_ORDER_CATEGORY,
      ordersCategory: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateOrderCategorySaga({ ordersCategory, orderCategory }) {
  try {
    if(typeof orderCategory !== 'undefined' ){
      var isNew = false;
      const aux = (typeof orderCategory._id !== 'undefined') ?
        yield call(api.updateOrderCategoryData, orderCategory._id, orderCategory) :
        yield call(api.createOrderCategoryData, orderCategory);
      const result = yield call(api.getAll);
      
      if(typeof orderCategory._id === 'undefined' ){
        isNew = true;
        ordersCategory[0] = aux.data
      }

      yield put({
        type: UPDATE_ORDER_CATEGORY,
        ordersCategory: result.data,
        orderCategory: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteOrderCategorySaga({ ordersCategory, orderCategory }) {
  try {
    if(typeof orderCategory !== 'undefined' ){
      yield call(api.softDeleteOrderCategory, orderCategory);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_ORDER_CATEGORY,
        ordersCategory: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_ORDER_CATEGORY, getOrdersCategory),
    yield takeEvery(UPDATE_ORDER_CATEGORY_SAGA, updateOrderCategorySaga),
    yield takeEvery(DELETE_ORDER_CATEGORY_SAGA, deleteOrderCategorySaga),
  ]);
}
