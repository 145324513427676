import http from "../api";

class FinanceCategoryDataService {
    getAll() {
        return http({
            'url':'/financeCategory',
            'method':'GET',
            // 'responseType': 'json',
            // 'params': {
            //     'search':'parameter',
            // }
        });
    }

    getFinanceCategoryById(id) {
        return http({
            'url':'/financeCategory/'+id,
            'method':'GET'
        });
    }

    createFinanceCategoryData(data) {
        return http({
            'url':'/financeCategory',
            'method':'POST',
            'data': data
        });
    }

    updateFinanceCategoryData(id, data) {
        return http({
            'url':'/financeCategory/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteFinanceCategory(id) {
        return http({
            'url':'/financeCategory/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeFinanceCategory(id) {
        return http({
            'url':'/financeCategory/'+id,
            'method':'DELETE'
        });
    }
}

export default new FinanceCategoryDataService();