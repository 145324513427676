import clone from 'clone';
import {
    UPDATE_TASK
} from './actionsTypes'

const initState = {
  tasks: [],
  initialTasks: false,
  currentTask: {},
  editableTask: {},
  isNewTask: false,
  enableEditView: false,
  task: {
    key: null,
    _id: 0,
    name: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_TASK: {
      const currentTask = action.task
        ? action.task
        : state.currentTask;

      return {
        ...state,
        tasks: action.tasks,
        currentTask: clone(currentTask),
        initialTasks: true,
        isNewTask: action.isNew,
        enableEditView: false,
      };
    }
    default:
      return state;
  }
}
