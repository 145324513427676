import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_TASK, UPDATE_TASK, UPDATE_TASK_SAGA, DELETE_TASK_SAGA } from './actionsTypes';

import api from '@iso/services/repository/task';

export function* getTasks() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_TASK,
      tasks: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updateTaskSaga({ tasks, task }) {
  try {
    if(typeof task !== 'undefined' ){
      var isNew = false;
      const aux = (typeof task._id !== 'undefined') ?
        yield call(api.updateTaskData, task._id, task) :
        yield call(api.createTaskData, task);
      const result = yield call(api.getAll);
      
      if(typeof task._id === 'undefined' ){
        isNew = true;
        tasks[0] = aux.data
      }

      yield put({
        type: UPDATE_TASK,
        tasks: result.data,
        task: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deleteTaskSaga({ tasks, task }) {
  try {
    if(typeof task !== 'undefined' ){
      yield call(api.softDeleteTask, task);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_TASK,
        tasks: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_TASK, getTasks),
    yield takeEvery(UPDATE_TASK_SAGA, updateTaskSaga),
    yield takeEvery(DELETE_TASK_SAGA, deleteTaskSaga),
  ]);
}
