import clone from 'clone';
import { newBillReceive } from './config';
import {
    UPDATE_BILLS_RECEIVE,
    SELECT_CURRENT_BILLS_RECEIVE
} from './actionsTypes'

const initState = {
  billsReceive: [],
  initialBillsReceive: false,
  currentBillReceive: {},
  editableBillReceive: {},
  isNewBillReceive: false,
  enableEditView: false,
  billReceive: {
    key: null,
    _id: 0,
    fornecedor: "",
    history: "",
    emission: "",
    maturity: "",
    value: "",
    netValue: "",
    balance: "",
    received: "",
    category: "",
    installments: "",
    occurrence: "",
    dayMaturity: "",
    situation: "Ativo", // inativo
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case UPDATE_BILLS_RECEIVE: {
      const currentBillReceive = action.billReceive
        ? action.billReceive
        : state.currentBillReceive;

      return {
        ...state,
        billsReceive: action.billsReceive,
        currentBillReceive: clone(currentBillReceive),
        initialBillsReceive: true,
        isNewBillReceive: false,
        enableEditView: false,
      };
    }
    case SELECT_CURRENT_BILLS_RECEIVE: {
      const billsReceive = state.billsReceive;
      const index = billsReceive.findIndex(billReceive => billReceive._id === action.id);
      const isNewBillReceive = index === -1;
      const currentBillReceive = isNewBillReceive
        ? {
            id: action.id,
            number: `#${action.id}`,
            key: action.id,
            ...newBillReceive,
          }
        : billsReceive[index];
      const enableEditView = isNewBillReceive;
      return {
        ...state,
        currentBillReceive,
        isNewBillReceive,
        enableEditView,
        editableBillReceive: clone(currentBillReceive),
      };
    }
    default:
      return state;
  }
}
