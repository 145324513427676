import http from "../api";

class UserDataService {
    getAll() {
        return http({
            'url':'/user',
            'method':'GET',
        });
    }

    getPermission() {
        return http({
            'url':'/user/getPermission',
            'method':'GET',
        });
    }

    getUserById(id) {
        return http({
            'url':'/user/'+id,
            'method':'GET'
        });
    }

    createUserData(data) {
        return http({
            'url':'/user',
            'method':'POST',
            'data': data
        });
    }

    updateUserData(id, data) {
        return http({
            'url':'/user/'+id,
            'method':'PUT',
            'data': data
        });
    }

    softDeleteUser(id) {
        return http({
            'url':'/user/softDelete/'+id,
            'method':'PUT'
        });
    }

    removeUser(id) {
        return http({
            'url':'/user/'+id,
            'method':'DELETE'
        });
    }
}

export default new UserDataService();