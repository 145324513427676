import { all, takeEvery, put, call } from 'redux-saga/effects';
import { GET_PAYMENT_CONDITION, UPDATE_PAYMENT_CONDITION, UPDATE_PAYMENT_CONDITION_SAGA, DELETE_PAYMENT_CONDITION_SAGA } from './actionsTypes';

import api from '@iso/services/repository/comercialCondition/paymentCondition';

export function* getPaymentConditions() {
  try {
    const result = yield call(api.getAll);
    yield put({
      type: UPDATE_PAYMENT_CONDITION,
      paymentConditions: result.data
    })
  } catch (error) {
    console.error(error);
  }
}

export function* updatePaymentConditionSaga({ paymentConditions, paymentCondition }) {
  try {
    if(typeof paymentCondition !== 'undefined' ){
      var isNew = false;
      const aux = (typeof paymentCondition._id !== 'undefined') ?
        yield call(api.updatePaymentConditionData, paymentCondition._id, paymentCondition) :
        yield call(api.createPaymentConditionData, paymentCondition);
      const result = yield call(api.getAll);
      
      if(typeof paymentCondition._id === 'undefined' ){
        isNew = true;
        paymentConditions[0] = aux.data
      }

      yield put({
        type: UPDATE_PAYMENT_CONDITION,
        paymentConditions: result.data,
        paymentCondition: aux.data,
        isNew
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* deletePaymentConditionSaga({ paymentConditions, paymentCondition }) {
  try {
    if(typeof paymentCondition !== 'undefined' ){
      yield call(api.softDeletePaymentCondition, paymentCondition);
      const result = yield call(api.getAll);

      yield put({
        type: UPDATE_PAYMENT_CONDITION,
        paymentConditions: result.data
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_PAYMENT_CONDITION, getPaymentConditions),
    yield takeEvery(UPDATE_PAYMENT_CONDITION_SAGA, updatePaymentConditionSaga),
    yield takeEvery(DELETE_PAYMENT_CONDITION_SAGA, deletePaymentConditionSaga),
  ]);
}
